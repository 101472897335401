/*-------- 4. Banner style ---------*/

.banner-wrap {
    position: relative;
    overflow: hidden;
    a {
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
        }
    }
    .banner-position-1 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 60px;
        @media #{$lg-layout} {
            left: 30px;
        }
        @media #{$md-layout} {
            left: 15px;
        }
        @media #{$xs-layout} {
            left: 15px;
        }
    }
    .banner-content {
        h3 {
            line-height: 39px;
            font-size: 30px;
            color: #363535;
            margin: 0 0 30px;
            @media #{$lg-layout} {
                line-height: 32px;
                font-size: 23px;
                margin: 0 0 10px;
            }
            @media #{$md-layout} {
                line-height: 26px;
                font-size: 18px;
                margin: 0 0 7px;
            }
            @media #{$xs-layout} {
                line-height: 26px;
                font-size: 20px;
                margin: 0 0 10px;
            }
            @media #{$sm-layout} {
                line-height: 39px;
                font-size: 30px;
                margin: 0 0 30px;
            }
        }
        .banner-btn {
            a {
                text-transform: uppercase;
                color: #363535;
                border-bottom: 1px solid #363535;
                padding-bottom: 1px;
                &:hover {
                    color: $theme-color;
                    border-bottom: 1px solid $theme-color;
                }
            }
        }
    }
    &:hover a img {
        transform: scale(1.1);
    }
}









/*-------- 7. Product style ---------*/

.product-tab-list {
	justify-content: center;
    a {
        display: inline-block;
        margin: 0 5px;
        h4 {
            font-size: 14px;
            line-height: 1;
            background-color: #f1f1f1;
            padding: 13px 25px 13px;
            margin: 0;
            text-transform: capitalize;
            font-weight: 600;
            @media #{$xs-layout} {
                padding: 10px 16px 10px;
                margin: 0 0 10px;
            }
        }
        &.active {
            h4 {
                background-color: $theme-color;
                color: #fff;
            }
        }
    }
    @media #{$xs-layout} {
        &.pb-50 {
            padding-bottom: 30px;
        }
    }
}

.product-wrap {
    position: relative;
    .product-img {
        position: relative;
        overflow: hidden;
        a {
            img {
                width: 100%;
            }
        }
        > span {
            position: absolute;
            text-align: center;
            color: #fff;
            background-color: #e32222;
            top: 10px;
            left: 10px;
            line-height: 1;
            padding: 3px 10px 5px;
        }
        .product-action {
            position: absolute;
            left: 0px;
            right: 0;
            display: flex;
            top: 50%;
            transform: translateY(-50%);
            justify-content: center;
            a {
                font-size: 22px;
                color: #333;
                background-color: #fff;
                display: inline-block;
                width: 42px;
                height: 42px;
                text-align: center;
                margin: 0px 1px 0px 1px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(20px);
                i{
                    line-height: 41px;
                }
                &:nth-child(1) {
                    transition-delay: 0.2s;
                }
                &:nth-child(2) {
                    transition-delay: 0.1s;
                }
                &:nth-child(3) {
                    transition-delay: 0s;
                }
                &:hover {
                    background-color: $theme-color;
                    color: $white;
                }
            }
        }
    }
    .product-content {
        h4 {
            font-size: 17px;
            margin: 0px 0 0px;
            font-weight: 500;
        }
        .product-rating {
            margin: 4px 0 4px;
            i {
                color: #ffa900;
                font-size: 16px;
                margin: 0 1px;
            }
        }
        .product-price {
            span {
                font-size: 16px;
                color: #333;
                font-weight: 500;
                position: relative;
                &.old {
                    color: #8e8e8e;
                    text-decoration: line-through;
                    font-size: 16px;
                    margin-left: 18px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    right: -13px;
                    top: 10px;
                    width: 7px;
                    height: 2px;
                    background-color: #000;
                }
                &:last-child::before {
                    display: none;
                }
            }
        }
        &.product-content-padding {
            padding: 22px 10px 20px;
        }
    }
    &:hover .product-action a {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &:hover .product-action a:nth-child(1) {
        transition-delay: 0s;
    }
    &:hover .product-action a:nth-child(2) {
        transition-delay: 0.2s;
    }
    &:hover .product-action a:nth-child(3) {
        transition-delay: 0.3s;
    }
    &.product-border-1 {
        border: 1px solid #f6f6f6;
    }
}

.pro-category-list-wrap {
    .pro-category-list-title {
        margin-bottom: 35px;
        @media #{$xs-layout} {
            margin-bottom: 20px;
        }
        h4 {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            position: relative;
            padding: 0 0 16px;
            &:before {
                background-color: #d2d2d2;
                content: "";
                position: absolute;
                height: 2px;
                width: 32px;
                left: 0;
                bottom: 0;
            }
        }
    }
    .single-pro-category-list {
        display: flex;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .category-list-img {
            position: relative;
            flex: 0 0 100px;
            a {
                img {
                    width: 100%;
                }
            }
            .hover-img {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                transition: opacity .5s ease,transform 2s cubic-bezier(0,0,.44,1.18);
                opacity: 0;
            }
            &:hover .hover-img {
                opacity: 1;
            }
        }
        .pro-category-content {
            margin-left: 20px;
            margin-top: 4px;
            h5 {
               font-weight: 600;
                font-size: 14px;
                text-transform: capitalize;
                display: block;
                margin: 0 0 3px;
                line-height: 18px;
            }
            span {
                font-size: 14px;
            }
        }
    }
}


.modal-dialog {
	margin: 2% auto 8%;
	max-width: 960px;
	width: 960px;
    padding: 35px;
    @media #{$md-layout} {
        width: 720px;
        max-width: 720px;
    }
    @media #{$xs-layout} {
        width: 100%;
        max-width: 100%;
        padding: 35px 0;
    }
    .modal-header {
        .close {
            color: #333;
            cursor: pointer;
            opacity: 1;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .modal-body {
        padding: 35px 15px;
    }
    .quickview-big-img {
        img {
            width: 100%;
        }
    }
}

.product-details-content {
    @media #{$lg-layout}{
        &.ml-70{
            margin-left: 0;
        }
    }
    @media #{$md-layout}{
        &.ml-70{
            margin-left: 0;
        }
    }
    @media #{$xs-layout}{
        &.ml-70{
            margin-left: 0;
            margin-top: 30px;
        }
    }
    &.quickview-content {
        @media #{$xs-layout} {
            margin-top: 30px;
        }
    }
    h2{
        font-size: 24px;
        margin: 0 0 0px;
        line-height: 1;
        font-weight: 500;
        @media #{$xs-layout} {
            font-size: 20px;
        }
    }
    .product-details-price {
        display: flex;
        align-items: center;
        margin: 15px 0 26px;
        @media #{$xs-layout} {
            margin: 12px 0 12px;
        }
        span {
            font-size: 24px;
            color: $theme-color;
            font-weight: 700;
            &.old {
                color: #4a4a4a;
                font-size: 18px;
                text-decoration: line-through;
                margin-left: 20px;
                font-weight: 400;
            }
        }
    }
    .pro-details-rating-wrap {
        display: flex;
        align-items: center;
        margin: 0 0 17px;
        @media #{$xs-layout} {
            margin: 0 0 10px;
        }
        .pro-details-rating {
            position: relative;
            margin-right: 39px;
            &:before {
                position: absolute;
                right: -19px;
                top: 4px;
                content: "";
                height: 15px;
                width: 2px;
                background-color: #d1d1d1;
            }
            i{
                color: #ffa900;
                font-size: 16px;
                margin-right: 3px;
            }
        }
        span {
            a {
               font-size: 15px;
                color: $theme-color;
                &:hover {
                    color: #000;
                }
            }
        }
    }
    p{
        font-size: 14px;
        line-height: 28px;
        margin: 0;
    }
    .pro-details-list{
        margin: 20px 0 34px;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 0 37px;
        ul {
            li{
                margin: 0 0 5px;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .pro-details-size-color{
        display: flex;
        @media #{$xs-layout}{
            display: block;
        }
        @media #{$sm-layout}{
            display: flex;
        }
        .pro-details-color-wrap {
            margin-right: 20px;
            overflow: hidden;
            @media #{$xs-layout}{
                margin-bottom: 20px;
            }
            @media #{$sm-layout}{
                margin-bottom: 0px;
            }
            span {
                display: block;
                font-weight: 600;
                margin: 0 0 15px;
                font-size: 15px;
            }
            .pro-details-color-content{
                ul {
                    li {
                        border-radius: 50px;
                        cursor: pointer;
                        display: block;
                        float: left;
                        height: 14px;
                        margin-right: 15px;
                        position: relative;
                        transition: all 0.4s ease 0s;
                        width: 14px;
                        &:last-child {
                            margin-right: 0px;
                        }
                        &.blue {
                            background-color: #4798f3;
                            border: 1px solid #4798f3;
                        }
                        &.maroon {
                            background-color: #736751;
                            border: 1px solid #736751;
                        }
                        &.gray {
                            background-color: #c0c0c0;
                            border: 1px solid #c0c0c0;
                        }
                        &.green {
                            background-color: #139c57;
                            border: 1px solid #139c57;
                        }
                        &.yellow {
                            background-color: #e28b37;
                            border: 1px solid #e28b37;
                        }
                    }
                }
            }
        }
        .pro-details-size {
            span {
                display: block;
                font-weight: 600;
                margin: 0 0 10px;
                font-size: 15px;
            }
            .pro-details-size-content {
                ul {
                    li{
                        display: inline-block;
                        list-style: outside none none;
                        margin: 0 2px 0 0;
                        a {
                            background-color: #f1f2f6;
                            color: #000;
                            display: inline-block;
                            font-size: 12px;
                            line-height: 1;
                            padding: 6px 9px 7px;
                            text-transform: uppercase;
                            &:hover {
                                background-color: $theme-color;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    .pro-details-affiliate {
        margin: 30px 0 25px;
    } 
    .pro-details-quality {
        display: flex;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 34px;
        .cart-plus-minus {
            border: 1px solid #e8e8e8;
            display: inline-block;
            height: 60px;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 80px;
            .qtybutton {
                color: #333;
                cursor: pointer;
                float: inherit;
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                margin: 0;
                position: absolute;
                text-align: center;
                transition: all 0.3s ease 0s;
                width: 24px;
            }
            .dec.qtybutton {
                height: 60px;
                left: 0;
                padding-top: 20px;
                top: 0;
            }
            .inc.qtybutton {
                height: 60px;
                padding-top: 20px;
                right: 0;
                top: 0;
            }
            input.cart-plus-minus-box {
                background: transparent none repeat scroll 0 0;
                border: medium none;
                color: #333;
                float: left;
                font-size: 14px;
                height: 60px;
                margin: 0;
                padding: 0;
                text-align: center;
                width: 80px;
            }
        }
        .pro-details-cart{
            margin: 0 25px 0 10px;
            @media #{$xs-layout}{
                margin: 0 10px 0 10px;
            }
            @media #{$md-layout}{
                margin: 0 17px 0 10px;
            }
            a {
                color: #fff;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                background-color: #333;
                display: inline-block;
                line-height: 1;
                padding: 23px 38px 23px;
                letter-spacing: .8px;
                @media #{$xs-layout}{
                    padding: 23px 12px 23px;
                }
                @media #{$md-layout}{
                    padding: 22px 22px 22px;
                }
                z-index: 99;
                &:hover {
                    border: none;
                }
            }
        }
        .pro-details-wishlist {
            a {
                font-size: 20px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        .pro-details-compare {
            a{
                font-size: 20px;
                margin-left: 25px;
                @media #{$xs-layout}{
                    margin-left: 10px;
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    .pro-details-meta {
        display: flex;
        margin: 0 0 10px;
        span {
            font-size: 15px;
            margin-right: 5px;
            font-weight: 600;
        }
        ul{
            li{
                display: inline-block;
                margin: 0 3px 0 0;
                a{
                    font-size: 14px;
                    @media #{$xs-layout}{
                        font-size: 14px;
                    }
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    .pro-details-social {
        margin: 24px 0 0;
        ul {
            display: flex;
            li{
                margin-right: 40px;
                &:last-child {
                    margin-right: 0px;
                }
                a{
                    font-size: 16px;
                    color: #343538;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    &.pro-details-slider-content {
        @media #{$xs-layout}{
            &.mt-50 {
                margin-top: 30px;
            }
        }
        .product-details-price {
            justify-content: center;
        }
        p {
            width: 80%;
            margin: 0 auto;
            @media #{$lg-layout}{
                width: 80%;
            }
            @media #{$md-layout}{
                width: 100%;
            }
            @media #{$xs-layout}{
                width: 100%;
            }
        }
        .pro-details-rating-wrap {
            justify-content: center;
        }
        .pro-details-size-color {
            justify-content: center;
            text-align: left;
            @media #{$xs-layout}{
                text-align: center;
                & .pro-details-color-content {
                    justify-content: center;
                    display: flex;
                }
            }
        }
        .pro-details-quality {
            justify-content: center;
        }
        .pro-details-meta {
            justify-content: center;
        }
        .pro-details-social {
            ul {
                justify-content: center;
            }
        }
    }
}


.quickview-wrap {
    .nav-style-2.owl-carousel > .owl-nav div {
        color: #1f2226;
        display: inline-block;
        font-size: 13px;
        left: 0px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease 0s;
        border: 1px solid #e1e2e2;
        background-color: #ffffff;
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 100%;
        &:hover {
            color: $white;
            background-color: $theme-color;
            border: 1px solid $theme-color;
        }
        &.owl-next {
            right: 0px;
            left: auto;
        }
    }
    &:hover .nav-style-2.owl-carousel > .owl-nav div {
        opacity: 1;
        visibility: visible;
    }
}









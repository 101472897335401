/*-------- 26. About us style ---------*/

.about-content {
    margin-right: 30px;
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    h3 {
        font-weight: 700;
        font-size: 32px;
        text-transform: capitalize;
        margin: 0 0 20px;
    }
    h6 {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        margin: 0 0 20px;
    }
    p {
        font-size: 15px;
        margin-bottom: 23px;
        line-height: 28px;
    }
}
.about-img {
    img {
        width: 100%;
    }
}
.team-wrapper {
    position: relative;
    .team-img {
        position: relative;
        overflow: hidden;
        &::before {
            background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.90) 100%) repeat scroll 0 0;
            bottom: 0;
            content: "";
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.3s ease 0s;
            transform: translate3d(0px, 50%, 0px);
        }
        img {
            width: 100%;
        }
        .team-action {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease 0s;
            a {
                font-size: 14px;
                margin: 0 3px;
                display: inline-block;
                color: #fff;
                width: 35px;
                height: 35px;
                line-height: 35px;
                border: 1px solid transparent;
                &.facebook {
                    background-color: #3b5999;
                    &:hover {
                        color: #3b5999;
                        border: 1px solid #3b5999;
                        background-color: transparent;
                    }
                }
                &.twitter {
                    background-color: #55acee;
                    &:hover {
                        color: #55acee;
                        border: 1px solid #55acee;
                        background-color: transparent;
                    }
                }
                &.instagram {
                    background-color: #e4405f;
                    &:hover {
                        border: 1px solid #e4405f;
                        color: #e4405f;
                        background-color: transparent;
                    }
                }
            }
        }
    }
    .team-content {
        box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
        padding: 16px 5px 18px;
        h4 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 4px;
            text-transform: capitalize;
        }
        span {
            font-size: 15px;
            font-style: italic;
        }
    }
    &:hover .team-img::before {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
    }
    &:hover .team-action {
        opacity: 1;
        visibility: visible;
    }
}





/*-------- 17. Shop page style ---------*/

.shop-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$xs-layout}{
        display: block;
    }
    @media #{$sm-layout}{
        display: flex;
    }
    .select-shoing-wrap {
        display: flex;
        @media #{$xs-layout}{
            display: block;
        }
        @media #{$sm-layout}{
            display: flex;
        }
        .shop-select {
            margin-right: 50px;
            @media #{$xs-layout}{
                margin-right: 112px;
            }
            @media #{$sm-layout}{
                margin-right: 50px;
            }
            select {
                font-size: 15px;
                -moz-appearance: none;
                -webkit-appearance: none;
                min-width: 150px;
                cursor: pointer;
                background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 0px center;
                text-transform: capitalize;
                
            }
        }
        p{
            margin: 0;
            font-size: 15px;
            @media #{$xs-layout}{
                margin: 5px 0 10px;
            }
            @media #{$sm-layout}{
                margin: 0px;
            }
        }
    }
    .shop-tab {
        a {
            font-size: 24px;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
            &.active {
                color: #ff3535;
            }
        }
    }
}


.pro-pagination-style {
    @media #{$xs-layout}{
        &.mt-20 {
            margin-top: 10px;
        }
    }
    ul {
        li {
            display: inline-block;
            margin: 0 4px;
            a {
                display: inline-block;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                border-radius: 100%;
                color: $theme-color;
                box-shadow: 0 0px 12px 0.8px rgba(0, 0, 0, 0.1);
                &:hover {
                    background-color: $theme-color;
                    color: #fff;
                }
            }
            a.active {
                background-color: $theme-color;
                color: #fff;
                box-shadow: none;
                &:hover {
                    background-color: #333;
                }
            }
            a.prev,
            a.next {
                background-color: #f6f6f6;
                color: $theme-color;
                font-size: 17px;
                box-shadow: none;
                &:hover {
                    background-color: $theme-color;
                    color: #fff;
                }
            }
        }
    }
}

.shop-list-wrap {
    .shop-list-content {
        @media #{$xs-layout}{
            margin-top: 20px;
        }
        @media #{$sm-layout}{
            margin-top: 0px;
        }
        h3 {
            font-size: 20px;
            margin: 0;
            font-weight: 500;
            @media #{$xs-layout}{
                font-size: 20px;
                line-height: 20px;
            }
            a{
                &:hover {
                    color: $theme-color;
                }
            }
        }
        .product-list-price {
            margin: 8px 0 13px;
            @media #{$md-layout}{
                margin: 13px 0 19px;
            }
            @media #{$xs-layout}{
                margin: 8px 0 5px;
            }
            span {
                color: #333;
                font-size: 20px;
                display: inline-block;
                line-height: 1;
                font-weight: 500;
                &.old {
                    text-decoration: line-through;
                    font-size: 18px;
                    color: #8e8e8e;
                    margin-left: 15px;
                }
            }
        }
        .product-list-rating {
            margin: 6px 0 0;
            i {
                font-size: 16px;
                color: #ffa900;
                margin: 0 1px 0;
            }
        }
        p{
            margin: 15px 0 35px;
            line-height: 28px;
            font-size: 15px;
            @media #{$xl-layout}{
                margin: 10px 0 20px;
            }
            @media #{$xs-layout}{
                margin: 0px 0 15px;
            }
            @media #{$md-layout}{
                margin: 12px 0 20px;
            }
            @media #{$lg-layout}{
                margin: 15px 0 20px;
            }
        }
        .shop-list-btn-wrap {
            display: flex;
            .shop-list-cart {
                margin-right: 15px;
                a {
                    background-color: #333;
                    padding: 15px 25px;
                    font-weight: 600;
                }
            }
            .shop-list-wishlist {
                a {
                    background-color: #333;
                    padding: 11px 19px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }
    @media #{$xs-layout}{
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}










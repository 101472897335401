/*-------- 8. Testimonial style ---------*/

.single-testimonial {
    p {
        font-size: 28px;
        padding-top: 30px;
        color: #333;
        line-height: 49px;
        position: relative;
        margin: 0px auto 54px;
        width: 74%;
        @media #{$lg-layout} {
            font-size: 25px;
            line-height: 40px;
        }
        @media #{$md-layout} {
            font-size: 22px;
            line-height: 40px;
            width: 85%;
            padding-top: 20px;
        }
        @media #{$xs-layout} {
            font-size: 19px;
            line-height: 35px;
            padding-top: 20px;
            width: 100%;
            margin: 0px auto 50px;
        }
    }
    span {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        position: relative;
        text-transform: uppercase;
        color: #333;
        &:before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background-color: #333;
            border-radius: 100%;
            top: -31px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 999;
        }
    }
}

.testimonial-active {
    &.owl-carousel {
        .owl-item img {
            display: inline-block;
            width: auto;
        }  
    }
}







/*-------- 12. Feature style ---------*/

.feature-wrap {
    display: flex;
    align-items: center;
    background-color: #f4f6f8;
    padding: 40px 40px;
    border: 2px solid transparent;
    transition: all .3s ease 0s;
    @media #{$lg-layout} {
        padding: 25px 25px;
    }
    @media #{$md-layout} {
        padding: 15px 10px;
    }
    @media #{$xs-layout} {
        padding: 30px 20px;
    }
    .feature-img {
        margin-right: 20px;
        @media #{$md-layout} {
            margin-right: 15px;
            flex: 0 0 40px;
            img {
                width: 100%;
            }
        }
    }
    .feature-content {
        h5 {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 4px;
            @media #{$md-layout} {
                font-size: 14px;
            }
        }
    }
    &:hover {
        background-color: transparent;
        border: 2px solid #f4f6f8;
    }
}







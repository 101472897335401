/*-------- 16. Sidebar style ---------*/

.sidebar-style {
    @media #{$lg-layout}{
        &.mr-30 {
            margin-right: 0;
        }
    }
    @media #{$md-layout}{
        &.mr-30 {
            margin-right: 0;
        }
        margin-top: 30px;
    }
    @media #{$xs-layout}{
        &.mr-30 {
            margin-right: 0;
        }
        margin-top: 30px;
    }
    @media #{$lg-layout}{
        &.ml-30 {
            margin-left: 0;
        }
    }
    @media #{$md-layout}{
        &.ml-30 {
            margin-left: 0;
        }
        margin-top: 30px;
    }
    @media #{$xs-layout}{
        &.ml-30 {
            margin-left: 0;
        }
        margin-top: 40px;
    }
}

.sidebar-widget {
    h4.pro-sidebar-title{
        font-size: 17px;
        font-weight: 600;
        margin: 0;
    }
    .sidebar-widget-list {
        ul {
            li {
                align-items: center;
                position: relative;
                padding: 0px 0 18px;
                &:last-child {
                    padding: 0px 0 0px;
                }
                .sidebar-widget-list-left {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        z-index: 999;
                        width: auto;
                        &:checked ~ .checkmark {
                            background-color: #ff3535;
                            border: 2px solid #ff3535;
                        }
                        &:checked ~ .checkmark::after {
                            display: block;
                        }
                    }
                    .checkmark {
                        position: absolute;
                        top: 5px;
                        left: 0;
                        height: 15px;
                        width: 15px;
                        background-color: #fff;
                        border: 2px solid #888888;
                        border-radius: 3px;
                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                        &::after {
                            left: 3px;
                            top: 0px;
                            width: 5px;
                            height: 8px;
                            border: solid white;
                            border-top-width: medium;
                            border-right-width: medium;
                            border-bottom-width: medium;
                            border-left-width: medium;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
                a {
                    margin-left: 30px;
                    display: block;
                    span {
                        background-color: #eeeeee;
                        width: 25px;
                        height: 25px;
                        display: inline-block;
                        text-align: center;
                        line-height: 25px;
                        border-radius: 50px;
                        float: right;
                        transition: all .3s ease 0s;
                        font-size: 13px;
                    }
                    &:hover span {
                        color: #fff;
                        background-color: $theme-color;
                    }
                }
                
            }
        }
    }
    .sidebar-widget-archive {
        ul {
            li {
                margin-bottom: 10px;
                border: 1px solid #dee0e4;
                a {
                    padding: 12px 20px;
                    display: block;
                }
            }
        }
    }
    .sidebar-widget-tag {
        ul {
            li {
                display: inline-block;
                margin: 0 10px 15px 0;
                @media #{$lg-layout}{
                   margin: 0 5px 15px 0;
                }
                a {
                    display: inline-block;
                    background-color: #f6f6f6;
                    padding: 10px 17px 11px;
                    line-height: 1;
                    @media #{$lg-layout}{
                        padding: 9px 13px 12px;
                    }
                    @media #{$sm-layout}{
                        padding: 9px 14px 12px;
                    }
                    &:hover {
                        background-color: $theme-color;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.price-filter{
    .price-slider-amount {
        input {
            background: transparent;
            padding: 0;
            border: none;
            font-size: 15px;
            font-weight: 500;
        }
    }
    #slider-range {
        background: #dbdbdb none repeat scroll 0 0;
        border: medium none;
        border-radius: 0px;
        height: 5px;
        margin-bottom: 12px;
        margin-left: auto;
        margin-top: 5px;
        span {
            transition: all .0s ease 0s;
        }
        .ui-slider-range {
            position: absolute;
            display: block;
            border: 0;
            background: #ff3535 none repeat scroll 0 0;
            border: none;
            transition: all .0s ease 0s;
        }
        .ui-slider-handle {
            background: #fff none repeat scroll 0 0;
            border: medium none;
            border-radius: 50%;
            height: 15px;
            margin-left: 0;
            width: 15px;
            border: 4px solid #ff3535;
        }
    }
}










.pro-sidebar-search {
    .pro-sidebar-search-form {
        position: relative;
        input {
            background: transparent none repeat scroll 0 0;
            border: 1px solid #e6e6e6;
            font-size: 14px;
            height: 43px;
            padding: 2px 55px 2px 18px;
        }
        button {
            background: transparent none repeat scroll 0 0;
            border-color: #a1a5aa;
            border-image: none;
            border-style: none none none solid;
            border-width: medium medium medium 1px;
            padding: 3px 15px 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            color: #000;
            transition: all .3s ease 0s;
            font-size: 18px;
            i{
                position: relative;
                top: -2px;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
}

.single-sidebar-blog {
	border-bottom: 1px solid #dee0e4;
	display: flex;
	margin: 0 0 20px;
	padding: 0 0 20px;
    align-items: center;
    &:last-child {
        border-bottom: none;
    }
    .sidebar-blog-img {
        flex: 0 0 70px;
        margin: 0 20px 0 0;
        @media #{$lg-layout} {
            margin: 0 8px 0 0;
        }
        img {
            width: 100%;
        }
    }
    .sidebar-blog-content {
        span {
            display: block;
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        h4 {
            font-size: 14px;
            margin: 5px 0 0;
            font-weight: 500;
            @media #{$lg-layout} {
                font-size: 14px;
            }
        }
    }
}








/*-----------------------------------------------------------------------------------

    Template Name: Eliza -  eCommerce Bootstrap 4 Template
    Version: 2

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Banner style
    5. Deal style
    6. Section-title style
    7. Product style
    8. Testimonial style
    9. Blog style
    10. Instagram style
    11. Footer style
    12. Feature style
    13. Brand-logo style
    14. Video style
    15. Breadcrumb style
    16. Sidebar style
    17. Shop style
    18. Cart style
    19. Login register style
    20. My account style
    21. Product-details style
    22. Blog-details style
    23. Checkout style
    24. Contact-us style
    25. Compare style
    26. About style
    27. 404 style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Meddon|Montserrat:300,400,500,600,700,800,900|Open+Sans:400,600,700,800");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: #ffffff;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  margin-top: 0;
  color: #333;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #333;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.border-top-1 {
  border-top: 1px solid #ffffff;
}

.border-top-2 {
  border-top: 1px solid #e9ecf2;
}

.border-bottom-1 {
  border-bottom: 1px solid #d5d5d5;
}

a:hover {
  color: #e32222;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .mb-80 {
    margin-bottom: 60px;
  }
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .mb-100 {
    margin-bottom: 50px;
  }
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 50px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 45px;
  }
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

@media only screen and (max-width: 767px) {
  .pt-150 {
    padding-top: 70px;
  }
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .pb-65 {
    padding-bottom: 15px;
  }
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 20px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .pb-80 {
    padding-bottom: 50px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 55px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

@media only screen and (max-width: 767px) {
  .pb-150 {
    padding-bottom: 70px;
  }
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #333;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #333;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.input-group.divcenter.input-group .form-control {
  padding-left: 0px;
}

#mobile-menu-active {
  display: none;
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #e32222;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.height-100vh {
  height: 100vh;
}

/*--
    - Background Color
------------------------------------------*/
.bg-paleturquoise {
  background-color: #e3f8f3;
}

.bg-gray {
  background-color: #F4F6F8;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  right: 0;
  bottom: 0;
}

.default-btn a {
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  z-index: 1;
  color: #fff;
  font-size: 12px;
  font-family: "montserrat", sans-serif;
  letter-spacing: .8px;
}

.default-btn a.btn-color {
  color: #fff;
}

.default-btn a.btn-bg-black {
  background-color: #202020;
}

.default-btn a.btn-bg-theme {
  background-color: #e32222;
}

.default-btn a.btn-size-md {
  padding: 24px 35px 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-btn a.btn-size-md {
    padding: 20px 28px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .default-btn a.btn-size-md {
    padding: 20px 25px 20px;
  }
}

.default-btn a.btn-size-xs {
  padding: 20px 35px 20px;
}

.default-btn a.btn-size-xs-2 {
  padding: 12px 20px 12px;
}

.default-btn a.btn-style-outline {
  background-color: transparent;
  border: 1px solid #ff5151;
}

.default-btn a.border-radious {
  border-radius: 50px;
}

.btn-hover a {
  position: relative;
  transition: all .5s ease-in-out 0s;
}

.btn-hover a:hover {
  color: #fff;
}

.btn-hover a::before, .btn-hover a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.btn-hover a::after {
  width: 0;
  left: auto;
  right: 0;
  background: #e32222;
}

.btn-hover a.black-color::after {
  background: #333;
}

.btn-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.padding-10-row-col .container-fluid {
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.padding-10-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.padding-5-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-5-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-5-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-5-row-col .row div[class^="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*-------- 2. Header style ---------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-header-wrap {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-header-wrap {
    display: none;
  }
}

.header-small-mobile {
  display: none;
  padding: 25px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-mobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-mobile {
    display: block;
  }
}

.section-padding-1 .container-fluid {
  padding: 0 130px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.sticky-bar.stick {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  background-color: #fff;
  border-bottom: 0 solid #4a90e2;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  transition: all .3s ease 0s;
}

.logo {
  transition: all .3s ease 0s;
}

.main-menu {
  display: flex;
  justify-content: center;
}

.main-menu > nav > ul > li {
  display: inline-block;
  margin: 0 18px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li {
    margin: 0 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li {
    margin: 0 12px;
  }
}

.main-menu > nav > ul > li.angle-shape::before {
  border-bottom: 16px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
  z-index: 9999;
}

.main-menu > nav > ul > li > a {
  font-size: 14px;
  display: inline-block;
  line-height: 108px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 500;
  font-family: "montserrat", sans-serif;
  letter-spacing: .8px;
}

.main-menu > nav > ul > li ul.submenu {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  display: block;
  left: 0;
  padding: 22px 30px 24px;
  position: absolute;
  top: 100%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 190px;
  z-index: 999;
}

.main-menu > nav > ul > li ul.submenu li {
  margin-bottom: 16px;
}

.main-menu > nav > ul > li ul.submenu li:last-child {
  margin-bottom: 0px;
}

.main-menu > nav > ul > li ul.submenu li a {
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  letter-spacing: .2px;
  display: block;
  text-transform: capitalize;
}

.main-menu > nav > ul > li ul.submenu li a:hover {
  color: #e32222;
}

.main-menu > nav > ul > li ul.mega-menu {
  background-color: #fff;
  display: block;
  left: -273px;
  padding: 23px 30px 62px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 972px;
  z-index: 999;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.mega-menu {
    width: 900px;
  }
}

.main-menu > nav > ul > li ul.mega-menu > li {
  display: inline-block;
  float: left;
  padding-left: 30px;
  padding-right: 0;
  width: 25%;
}

.main-menu > nav > ul > li ul.mega-menu > li a.menu-title {
  color: #000000;
  font-size: 16px;
  letter-spacing: .2px;
  display: block;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 18px;
}

.main-menu > nav > ul > li ul.mega-menu > li ul {
  margin-top: 23px;
}

.main-menu > nav > ul > li ul.mega-menu > li ul li {
  margin-bottom: 19px;
}

.main-menu > nav > ul > li ul.mega-menu > li ul li:last-child {
  margin-bottom: 0px;
}

.main-menu > nav > ul > li ul.mega-menu > li ul li a {
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  letter-spacing: .2px;
  display: block;
  text-transform: capitalize;
}

.main-menu > nav > ul > li ul.mega-menu > li ul li a:hover {
  color: #e32222;
}

.main-menu > nav > ul > li:hover > ul.mega-menu {
  transform: rotateX(0deg);
  visibility: visible;
}

.main-menu > nav > ul > li:hover > ul.submenu {
  transform: rotateX(0deg);
  visibility: visible;
}

.main-menu > nav > ul > li:hover a {
  color: #e32222;
}

.main-menu > nav > ul > li:hover.angle-shape::before {
  opacity: 1;
  visibility: visible;
}

.header-right-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 38px 0;
}

.header-right-wrap .same-style > a {
  display: inline-block;
  font-size: 25px;
}

.header-right-wrap .same-style.cart-wrap a {
  position: relative;
}

.header-right-wrap .same-style.cart-wrap a span {
  position: absolute;
  font-size: 11px;
  height: 18px;
  line-height: 17px;
  color: #fff;
  display: block;
  top: -8px;
  right: -5px;
  min-width: 18px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background-color: #e32222;
  font-weight: bold;
}

.header-right-wrap .setting-wrap .setting-content {
  background: #fff none repeat scroll 0 0;
  padding: 26px 30px 32px;
  position: absolute;
  right: 0;
  top: 100%;
  width: 300px;
  z-index: 9999;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  max-height: 636px;
  overflow-y: auto;
  display: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-right-wrap .setting-wrap .setting-content {
    max-height: 510px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap .setting-wrap .setting-content {
    max-height: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .setting-wrap .setting-content {
    max-height: 400px;
  }
}

.header-right-wrap .setting-wrap .setting-content ul li {
  padding-bottom: 30px;
}

.header-right-wrap .setting-wrap .setting-content ul li:last-child {
  padding-bottom: 0px;
}

.header-right-wrap .setting-wrap .setting-content ul li h4 {
  font-size: 16px;
  margin: 0;
  color: #000000;
  padding-bottom: 19px;
  border-bottom: 1px solid #cccccc;
  font-family: "montserrat", sans-serif;
  font-weight: 500;
}

.header-right-wrap .setting-wrap .setting-content ul li ul {
  padding-top: 18px;
}

.header-right-wrap .setting-wrap .setting-content ul li ul li {
  padding-bottom: 25px;
}

.header-right-wrap .setting-wrap .setting-content ul li ul li:last-child {
  padding-bottom: 0px;
}

.header-right-wrap .setting-wrap .setting-content ul li ul li a {
  letter-spacing: .2px;
  color: #000000;
  font-weight: 300;
  display: block;
  line-height: 1;
}

.header-right-wrap .setting-wrap .setting-content ul li ul li a:hover {
  color: #e32222;
}

.header-right-wrap .setting-wrap:hover {
  color: #e32222;
}

.search-content-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.97);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-content-wrap.search-visible {
  visibility: visible;
  opacity: 1;
}

.search-content-wrap.search-visible .search-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-content-wrap > .search-close {
  font-size: 40px;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s;
  color: #fff !important;
}

.search-content-wrap > .search-close:hover {
  transform: rotate(90deg);
}

.search-content-wrap > .search-close i:hover {
  color: #e32222;
}

.search-content-wrap .search-content {
  display: inline-block;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
  transform: scale(0.9);
}

.search-content-wrap .search-content p {
  font-size: 18px;
  margin: 0 0 15px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-family: "montserrat", sans-serif;
}

.search-content-wrap .search-content .search-form {
  position: relative;
}

.search-content-wrap .search-content .search-form input {
  width: 800px;
  background-color: transparent;
  box-shadow: 0 3px 0 0 rgba(255, 255, 255, 0.1);
  border: 0;
  text-align: center;
  font-size: 27px;
  padding: 34px 40px 25px 36px;
  color: rgba(255, 255, 255, 0.8);
  transition: all .3s ease-out;
  font-weight: 400;
  max-width: 100%;
  height: auto;
}

.search-content-wrap .search-content .search-form .button-search {
  position: absolute;
  top: 44px;
  display: block;
  right: 5px;
  border: 0;
  background: 0 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  height: 30px;
}

.search-content-wrap .search-content .search-form .button-search:hover {
  color: #e32222;
}

.wrapper-2 .body-overlay-2 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 50px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 55px;
  right: 55px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    right: 23px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #e32222;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 45px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  max-height: -webkit-calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 70px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 14px;
  margin: 0 0 4px;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  font-size: 18px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 8px 0 37px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  float: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.stick .main-menu > nav > ul > li > a {
  line-height: 80px;
}

.stick .main-menu > nav > ul > li > a span {
  top: 8px;
}

.stick .header-right-wrap {
  padding: 24px 0;
}

.stick .header-small-mobile .header-right-wrap {
  padding: 3px 0 0;
}

.header-small-mobile .header-right-wrap {
  padding: 3px 0 0;
}

.header-small-mobile .header-right-wrap .cart-wrap .shopping-cart-content {
  top: 51px;
  height: calc(100vh - 79px);
}

.header-small-mobile .header-right-wrap .cart-wrap .shopping-cart-content .shopping-cart-top {
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.header-small-mobile .header-right-wrap .cart-wrap .shopping-cart-content.show {
  right: -40px;
}

.header-small-mobile .header-right-wrap .cart-wrap .shopping-cart-content .shopping-cart-bottom {
  margin-top: 12px;
}

.header-small-mobile .header-right-wrap .cart-wrap .shopping-cart-content .shopping-cart-bottom a {
  padding: 9px 40px 10px;
  font-size: 13px;
}

.header-small-mobile .header-right-wrap .cart-wrap .shopping-cart-content .shopping-cart-bottom .shopping-cart-total {
  padding: 12px 0px 11px;
  margin: 15px 0 15px;
}

.header-small-mobile .header-right-wrap .mobile-off-canvas {
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .header-small-mobile .header-right-wrap .mobile-off-canvas {
    margin-left: 12px;
  }
}

.header-small-mobile .header-right-wrap .mobile-off-canvas a {
  font-size: 24px;
}

.main-wrapper .body-overlay {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: 1;
  visibility: visible;
}

.mobile-off-canvas-active {
  position: fixed;
  top: 0;
  width: 330px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active {
    width: 260px;
    right: -260px;
  }
}

.mobile-off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}

.mobile-off-canvas-active .mobile-aside-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  background-color: #343538;
}

.mobile-off-canvas-active .mobile-aside-close i {
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #fff;
}

.mobile-off-canvas-active .mobile-aside-close:hover i {
  color: #e32222;
  transform: rotate(90deg);
}

.mobile-off-canvas-active .header-mobile-aside-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 30px 20px 50px;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
  border-bottom: 1px solid #ddd;
  margin-bottom: 27px;
  padding-bottom: 40px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #000;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: #e32222;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 36px;
  padding-bottom: 27px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #000;
  transition: all 250ms ease-out;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #000;
  transition: 0.4s;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-weight: 500;
  font-family: "montserrat", sans-serif;
  color: #000;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a:hover {
  color: #e32222;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: #e32222;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 300;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 31px;
  padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    display: block;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 13px;
  display: block;
  font-weight: 500;
  font-family: "montserrat", sans-serif;
  color: #000;
  text-transform: uppercase;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 10px;
  position: relative;
  top: 8px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  color: #333;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #e32222;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #e32222;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .quick-info {
  margin-bottom: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .quick-info ul li {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  font-family: "montserrat", sans-serif;
  color: #000;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .quick-info ul li a {
  color: #000;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .quick-info ul li a:hover {
  color: #e32222;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .quick-info ul li:last-child {
  margin-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .quick-info ul li i {
  font-size: 18px;
  margin: 0 5px 0 0;
}

.transparent-bar {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 0;
}

/*-------- 3. Slider style ---------*/
.slider-height-1 {
  height: 840px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 720px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: auto;
    padding: 110px 0 70px;
  }
}

.slider-content h3 {
  font-size: 36px;
  color: #e32222;
  font-family: "Meddon", cursive;
  margin: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content h3 {
    font-size: 32px;
  }
}

.slider-content h1 {
  font-size: 48px;
  font-weight: bold;
  caption-side: #000000;
  line-height: 69px;
  letter-spacing: .8px;
  text-transform: uppercase;
  margin: 16px 0 43px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content h1 {
    font-size: 40px;
    margin: 16px 0 35px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content h1 {
    font-size: 35px;
    line-height: 45px;
    margin: 16px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content h1 {
    font-size: 28px;
    line-height: 40px;
    margin: 12px 0 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content h1 {
    font-size: 24px;
    line-height: 34px;
    margin: 5px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 {
    margin-top: 0px;
  }
}

.slider-content-2 h3 {
  font-size: 50px;
  color: #e32222;
  letter-spacing: .8px;
  margin: 0;
  font-family: "Meddon", cursive;
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h3 {
    font-size: 45px;
  }
}

.slider-content-2 h1 {
  font-weight: bold;
  color: #000000;
  font-size: 80px;
  text-transform: uppercase;
  letter-spacing: .8px;
  margin: 14px 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h1 {
    font-size: 40px;
    margin: 14px 0 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 h1 {
    font-size: 40px;
  }
}

.slider-content-2 h4 {
  font-size: 16px;
  color: #000000;
  letter-spacing: 9px;
  text-transform: uppercase;
  margin: 0 0 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h4 {
    font-size: 14px;
    letter-spacing: 7px;
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h4 {
    font-size: 15px;
    letter-spacing: 4px;
    margin: 0 0 40px;
  }
}

.slider-single-img-2 {
  margin: 0 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-2 {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-2 {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content {
    padding: 50px 0 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content {
    padding: 0px 0 0px;
  }
}

.owl-item .slider-content * {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-item.active .slider-animated-1 h3 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 h1 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1 a {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-1.slider-single-img img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-2 h3 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-2 h1 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-2 h4 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-2 a {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.owl-item.active .slider-animated-2.slider-single-img-2 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.nav-style-1.owl-carousel .owl-nav div {
  color: #1f2226;
  display: inline-block;
  font-size: 22px;
  left: 18px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1.owl-carousel .owl-nav div {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1.owl-carousel .owl-nav div {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1.owl-carousel .owl-nav div {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
}

.nav-style-1.owl-carousel .owl-nav div:hover {
  background-color: #e32222;
  color: #ffffff;
  border: 1px solid #e32222;
}

.nav-style-1.owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 18px;
}

.slider-area:hover .nav-style-1.owl-carousel > .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/*-------- 4. Banner style ---------*/
.banner-wrap {
  position: relative;
  overflow: hidden;
}

.banner-wrap a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.banner-wrap .banner-position-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-1 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-1 {
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    left: 15px;
  }
}

.banner-wrap .banner-content h3 {
  line-height: 39px;
  font-size: 30px;
  color: #363535;
  margin: 0 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content h3 {
    line-height: 32px;
    font-size: 23px;
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content h3 {
    line-height: 26px;
    font-size: 18px;
    margin: 0 0 7px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content h3 {
    line-height: 26px;
    font-size: 20px;
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content h3 {
    line-height: 39px;
    font-size: 30px;
    margin: 0 0 30px;
  }
}

.banner-wrap .banner-content .banner-btn a {
  text-transform: uppercase;
  color: #363535;
  border-bottom: 1px solid #363535;
  padding-bottom: 1px;
}

.banner-wrap .banner-content .banner-btn a:hover {
  color: #e32222;
  border-bottom: 1px solid #e32222;
}

.banner-wrap:hover a img {
  transform: scale(1.1);
}

/*-------- 5. Deal style ---------*/
.deal-img img {
  width: 100%;
}

.deal-content h3 {
  font-size: 36px;
  color: #e32222;
  font-family: "Meddon", cursive;
  margin: 0;
  line-height: 55px;
}

.deal-content h2 {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 40px;
  margin: 4px 0 13px;
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .deal-content h2 {
    font-size: 28px;
    margin: 4px 0 10px;
    line-height: 25px;
  }
}

.deal-content > p {
  color: #333;
  font-style: italic;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
}

.deal-content .timer {
  margin: 37px 0 40px;
}

@media only screen and (max-width: 767px) {
  .deal-content .timer {
    margin: 30px 0 20px;
  }
}

.deal-content .timer span {
  display: inline-block;
  width: 90px;
  height: 90px;
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  border: 1px solid #333;
  font-family: "montserrat", sans-serif;
  margin: 0 12px;
  padding: 17px 0 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content .timer span {
    width: 85px;
    height: 85px;
    margin: 0 7px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content .timer span {
    margin: 0 4px 12px;
  }
}

.deal-content .timer span p {
  text-transform: capitalize;
  font-weight: 500;
  margin: 7px 0 0;
  font-family: "montserrat", sans-serif;
  color: #333;
  font-size: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area.bg-img {
    background-position: 57%;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area.bg-img {
    background-position: 68%;
    position: relative;
  }
  .deal-area.bg-img:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    opacity: .5;
    content: "";
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area.bg-img {
    background-position: 68%;
    position: relative;
  }
  .deal-area.bg-img:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    opacity: .5;
    content: "";
    height: 100%;
  }
}

/*-------- 6. Section title style ---------*/
.section-title h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    margin-bottom: 10px;
    font-size: 26px;
  }
}

.section-title p {
  margin: 0 auto;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .section-title.mb-45 {
    margin-bottom: 30px;
  }
}

/*-------- 7. Product style ---------*/
.product-tab-list {
  justify-content: center;
}

.product-tab-list a {
  display: inline-block;
  margin: 0 5px;
}

.product-tab-list a h4 {
  font-size: 14px;
  line-height: 1;
  background-color: #f1f1f1;
  padding: 13px 25px 13px;
  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .product-tab-list a h4 {
    padding: 10px 16px 10px;
    margin: 0 0 10px;
  }
}

.product-tab-list a.active h4 {
  background-color: #e32222;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .product-tab-list.pb-50 {
    padding-bottom: 30px;
  }
}

.product-wrap {
  position: relative;
}

.product-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.product-wrap .product-img a img {
  width: 100%;
}

.product-wrap .product-img > span {
  position: absolute;
  text-align: center;
  color: #fff;
  background-color: #e32222;
  top: 10px;
  left: 10px;
  line-height: 1;
  padding: 3px 10px 5px;
}

.product-wrap .product-img .product-action {
  position: absolute;
  left: 0px;
  right: 0;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
}

.product-wrap .product-img .product-action a {
  font-size: 22px;
  color: #333;
  background-color: #fff;
  display: inline-block;
  width: 42px;
  height: 42px;
  text-align: center;
  margin: 0px 1px 0px 1px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

.product-wrap .product-img .product-action a i {
  line-height: 41px;
}

.product-wrap .product-img .product-action a:nth-child(1) {
  transition-delay: 0.2s;
}

.product-wrap .product-img .product-action a:nth-child(2) {
  transition-delay: 0.1s;
}

.product-wrap .product-img .product-action a:nth-child(3) {
  transition-delay: 0s;
}

.product-wrap .product-img .product-action a:hover {
  background-color: #e32222;
  color: #ffffff;
}

.product-wrap .product-content h4 {
  font-size: 17px;
  margin: 0px 0 0px;
  font-weight: 500;
}

.product-wrap .product-content .product-rating {
  margin: 4px 0 4px;
}

.product-wrap .product-content .product-rating i {
  color: #ffa900;
  font-size: 16px;
  margin: 0 1px;
}

.product-wrap .product-content .product-price span {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  position: relative;
}

.product-wrap .product-content .product-price span.old {
  color: #8e8e8e;
  text-decoration: line-through;
  font-size: 16px;
  margin-left: 18px;
}

.product-wrap .product-content .product-price span::before {
  position: absolute;
  content: "";
  right: -13px;
  top: 10px;
  width: 7px;
  height: 2px;
  background-color: #000;
}

.product-wrap .product-content .product-price span:last-child::before {
  display: none;
}

.product-wrap .product-content.product-content-padding {
  padding: 22px 10px 20px;
}

.product-wrap:hover .product-action a {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.product-wrap:hover .product-action a:nth-child(1) {
  transition-delay: 0s;
}

.product-wrap:hover .product-action a:nth-child(2) {
  transition-delay: 0.2s;
}

.product-wrap:hover .product-action a:nth-child(3) {
  transition-delay: 0.3s;
}

.product-wrap.product-border-1 {
  border: 1px solid #f6f6f6;
}

.pro-category-list-wrap .pro-category-list-title {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .pro-category-list-wrap .pro-category-list-title {
    margin-bottom: 20px;
  }
}

.pro-category-list-wrap .pro-category-list-title h4 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  position: relative;
  padding: 0 0 16px;
}

.pro-category-list-wrap .pro-category-list-title h4:before {
  background-color: #d2d2d2;
  content: "";
  position: absolute;
  height: 2px;
  width: 32px;
  left: 0;
  bottom: 0;
}

.pro-category-list-wrap .single-pro-category-list {
  display: flex;
  margin-bottom: 20px;
}

.pro-category-list-wrap .single-pro-category-list:last-child {
  margin-bottom: 0;
}

.pro-category-list-wrap .single-pro-category-list .category-list-img {
  position: relative;
  flex: 0 0 100px;
}

.pro-category-list-wrap .single-pro-category-list .category-list-img a img {
  width: 100%;
}

.pro-category-list-wrap .single-pro-category-list .category-list-img .hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transition: opacity 0.5s ease, transform 2s cubic-bezier(0, 0, 0.44, 1.18);
  opacity: 0;
}

.pro-category-list-wrap .single-pro-category-list .category-list-img:hover .hover-img {
  opacity: 1;
}

.pro-category-list-wrap .single-pro-category-list .pro-category-content {
  margin-left: 20px;
  margin-top: 4px;
}

.pro-category-list-wrap .single-pro-category-list .pro-category-content h5 {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 3px;
  line-height: 18px;
}

.pro-category-list-wrap .single-pro-category-list .pro-category-content span {
  font-size: 14px;
}

.modal-dialog {
  margin: 2% auto 8%;
  max-width: 960px;
  width: 960px;
  padding: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-header .close {
  color: #333;
  cursor: pointer;
  opacity: 1;
}

.modal-dialog .modal-header .close:hover {
  color: #e32222;
}

.modal-dialog .modal-body {
  padding: 35px 15px;
}

.modal-dialog .quickview-big-img img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.ml-70 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.ml-70 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.ml-70 {
    margin-left: 0;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content {
    margin-top: 30px;
  }
}

.product-details-content h2 {
  font-size: 24px;
  margin: 0 0 0px;
  line-height: 1;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
  }
}

.product-details-content .product-details-price {
  display: flex;
  align-items: center;
  margin: 15px 0 26px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-price {
    margin: 12px 0 12px;
  }
}

.product-details-content .product-details-price span {
  font-size: 24px;
  color: #e32222;
  font-weight: 700;
}

.product-details-content .product-details-price span.old {
  color: #4a4a4a;
  font-size: 18px;
  text-decoration: line-through;
  margin-left: 20px;
  font-weight: 400;
}

.product-details-content .pro-details-rating-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 17px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-rating-wrap {
    margin: 0 0 10px;
  }
}

.product-details-content .pro-details-rating-wrap .pro-details-rating {
  position: relative;
  margin-right: 39px;
}

.product-details-content .pro-details-rating-wrap .pro-details-rating:before {
  position: absolute;
  right: -19px;
  top: 4px;
  content: "";
  height: 15px;
  width: 2px;
  background-color: #d1d1d1;
}

.product-details-content .pro-details-rating-wrap .pro-details-rating i {
  color: #ffa900;
  font-size: 16px;
  margin-right: 3px;
}

.product-details-content .pro-details-rating-wrap span a {
  font-size: 15px;
  color: #e32222;
}

.product-details-content .pro-details-rating-wrap span a:hover {
  color: #000;
}

.product-details-content p {
  font-size: 14px;
  line-height: 28px;
  margin: 0;
}

.product-details-content .pro-details-list {
  margin: 20px 0 34px;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 37px;
}

.product-details-content .pro-details-list ul li {
  margin: 0 0 5px;
}

.product-details-content .pro-details-list ul li:last-child {
  margin: 0;
}

.product-details-content .pro-details-size-color {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-size-color {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-size-color {
    display: flex;
  }
}

.product-details-content .pro-details-size-color .pro-details-color-wrap {
  margin-right: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-size-color .pro-details-color-wrap {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-size-color .pro-details-color-wrap {
    margin-bottom: 0px;
  }
}

.product-details-content .pro-details-size-color .pro-details-color-wrap span {
  display: block;
  font-weight: 600;
  margin: 0 0 15px;
  font-size: 15px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li {
  border-radius: 50px;
  cursor: pointer;
  display: block;
  float: left;
  height: 14px;
  margin-right: 15px;
  position: relative;
  transition: all 0.4s ease 0s;
  width: 14px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li:last-child {
  margin-right: 0px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.blue {
  background-color: #4798f3;
  border: 1px solid #4798f3;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.maroon {
  background-color: #736751;
  border: 1px solid #736751;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.gray {
  background-color: #c0c0c0;
  border: 1px solid #c0c0c0;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.green {
  background-color: #139c57;
  border: 1px solid #139c57;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content ul li.yellow {
  background-color: #e28b37;
  border: 1px solid #e28b37;
}

.product-details-content .pro-details-size-color .pro-details-size span {
  display: block;
  font-weight: 600;
  margin: 0 0 10px;
  font-size: 15px;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content ul li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 2px 0 0;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content ul li a {
  background-color: #f1f2f6;
  color: #000;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 6px 9px 7px;
  text-transform: uppercase;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content ul li a:hover {
  background-color: #e32222;
  color: #fff;
}

.product-details-content .pro-details-affiliate {
  margin: 30px 0 25px;
}

.product-details-content .pro-details-quality {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 34px;
}

.product-details-content .pro-details-quality .cart-plus-minus {
  border: 1px solid #e8e8e8;
  display: inline-block;
  height: 60px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 80px;
}

.product-details-content .pro-details-quality .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.product-details-content .pro-details-quality .cart-plus-minus .dec.qtybutton {
  height: 60px;
  left: 0;
  padding-top: 20px;
  top: 0;
}

.product-details-content .pro-details-quality .cart-plus-minus .inc.qtybutton {
  height: 60px;
  padding-top: 20px;
  right: 0;
  top: 0;
}

.product-details-content .pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #333;
  float: left;
  font-size: 14px;
  height: 60px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 80px;
}

.product-details-content .pro-details-quality .pro-details-cart {
  margin: 0 25px 0 10px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality .pro-details-cart {
    margin: 0 10px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-quality .pro-details-cart {
    margin: 0 17px 0 10px;
  }
}

.product-details-content .pro-details-quality .pro-details-cart a {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #333;
  display: inline-block;
  line-height: 1;
  padding: 23px 38px 23px;
  letter-spacing: .8px;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality .pro-details-cart a {
    padding: 23px 12px 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-quality .pro-details-cart a {
    padding: 22px 22px 22px;
  }
}

.product-details-content .pro-details-quality .pro-details-cart a:hover {
  border: none;
}

.product-details-content .pro-details-quality .pro-details-wishlist a {
  font-size: 20px;
}

.product-details-content .pro-details-quality .pro-details-wishlist a:hover {
  color: #e32222;
}

.product-details-content .pro-details-quality .pro-details-compare a {
  font-size: 20px;
  margin-left: 25px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality .pro-details-compare a {
    margin-left: 10px;
  }
}

.product-details-content .pro-details-quality .pro-details-compare a:hover {
  color: #e32222;
}

.product-details-content .pro-details-meta {
  display: flex;
  margin: 0 0 10px;
}

.product-details-content .pro-details-meta span {
  font-size: 15px;
  margin-right: 5px;
  font-weight: 600;
}

.product-details-content .pro-details-meta ul li {
  display: inline-block;
  margin: 0 3px 0 0;
}

.product-details-content .pro-details-meta ul li a {
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-meta ul li a {
    font-size: 14px;
  }
}

.product-details-content .pro-details-meta ul li a:hover {
  color: #e32222;
}

.product-details-content .pro-details-social {
  margin: 24px 0 0;
}

.product-details-content .pro-details-social ul {
  display: flex;
}

.product-details-content .pro-details-social ul li {
  margin-right: 40px;
}

.product-details-content .pro-details-social ul li:last-child {
  margin-right: 0px;
}

.product-details-content .pro-details-social ul li a {
  font-size: 16px;
  color: #343538;
}

.product-details-content .pro-details-social ul li a:hover {
  color: #e32222;
}

@media only screen and (max-width: 767px) {
  .product-details-content.pro-details-slider-content.mt-50 {
    margin-top: 30px;
  }
}

.product-details-content.pro-details-slider-content .product-details-price {
  justify-content: center;
}

.product-details-content.pro-details-slider-content p {
  width: 80%;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.pro-details-slider-content p {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.pro-details-slider-content p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.pro-details-slider-content p {
    width: 100%;
  }
}

.product-details-content.pro-details-slider-content .pro-details-rating-wrap {
  justify-content: center;
}

.product-details-content.pro-details-slider-content .pro-details-size-color {
  justify-content: center;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .product-details-content.pro-details-slider-content .pro-details-size-color {
    text-align: center;
  }
  .product-details-content.pro-details-slider-content .pro-details-size-color .pro-details-color-content {
    justify-content: center;
    display: flex;
  }
}

.product-details-content.pro-details-slider-content .pro-details-quality {
  justify-content: center;
}

.product-details-content.pro-details-slider-content .pro-details-meta {
  justify-content: center;
}

.product-details-content.pro-details-slider-content .pro-details-social ul {
  justify-content: center;
}

.quickview-wrap .nav-style-2.owl-carousel > .owl-nav div {
  color: #1f2226;
  display: inline-block;
  font-size: 13px;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
}

.quickview-wrap .nav-style-2.owl-carousel > .owl-nav div:hover {
  color: #ffffff;
  background-color: #e32222;
  border: 1px solid #e32222;
}

.quickview-wrap .nav-style-2.owl-carousel > .owl-nav div.owl-next {
  right: 0px;
  left: auto;
}

.quickview-wrap:hover .nav-style-2.owl-carousel > .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/*-------- 8. Testimonial style ---------*/
.single-testimonial p {
  font-size: 28px;
  padding-top: 30px;
  color: #333;
  line-height: 49px;
  position: relative;
  margin: 0px auto 54px;
  width: 74%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial p {
    font-size: 25px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    font-size: 22px;
    line-height: 40px;
    width: 85%;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    font-size: 19px;
    line-height: 35px;
    padding-top: 20px;
    width: 100%;
    margin: 0px auto 50px;
  }
}

.single-testimonial span {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
  color: #333;
}

.single-testimonial span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 100%;
  top: -31px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
}

.testimonial-active.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

/*-------- 9. Blog style ---------*/
.blog-wrap .blog-img {
  overflow: hidden;
}

.blog-wrap .blog-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.blog-wrap .blog-content .blog-category a {
  font-style: italic;
  color: #666;
  font-size: 15px;
}

.blog-wrap .blog-content .blog-category a:hover {
  color: #e32222;
}

.blog-wrap .blog-content h3 {
  font-weight: 700;
  font-size: 20px;
  margin: 7px 0 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 18px;
  }
}

.blog-wrap .blog-content .blog-meta a {
  display: inline-block;
  margin: 0 10px;
  font-size: 15px;
  color: #666;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content .blog-meta a {
    margin: 0 5px;
  }
}

.blog-wrap .blog-content .blog-meta a:hover {
  color: #e32222;
}

.blog-wrap .blog-content .blog-meta a i {
  font-size: 18px;
  padding-right: 5px;
}

.blog-wrap p {
  margin: 7px auto 6px;
  width: 90%;
}

@media only screen and (max-width: 767px) {
  .blog-wrap p {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap p {
    width: 85%;
  }
}

.blog-wrap .blog-btn a {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .8px;
  padding: 0 0 3px;
  border-bottom: 1px solid #666;
  display: inline-block;
  line-height: 1;
}

.blog-wrap .blog-btn a:hover {
  border-bottom: 1px solid #e32222;
}

.blog-wrap:hover .blog-img a img {
  transform: scale(1.1);
}

/*-------- 10. Instagram style ---------*/
.instagram-item {
  position: relative;
  overflow: hidden;
}

.instagram-item a {
  display: block;
}

.instagram-item a img {
  transition: all .5s ease 0s;
  transform: scale(1.1);
}

.instagram-item a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 99;
  font-size: 50px;
  color: #fff;
  visibility: hidden;
  transition: all .5s ease 0s;
}

.instagram-item a i:hover {
  color: #e32222;
}

.instagram-item a::before {
  background-color: rgba(52, 53, 56, 0.4);
  z-index: 9;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  right: 0;
  bottom: 0;
  transition: all .3s ease-out;
  opacity: 0;
  visibility: hidden;
}

.instagram-item:hover a img {
  transform: scale(1);
}

.instagram-item:hover a i {
  transform: translate(-50%, -50%) scale(1);
  visibility: visible;
}

.instagram-item:hover a::before {
  opacity: 1;
  visibility: visible;
}

/*-------- 11. Footer style ---------*/
.footer-widget .footer-title h3 {
  font-weight: 600;
  font-size: 18px;
  color: #333;
  margin: 0 0 33px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-title h3 {
    margin: 0 0 25px;
  }
}

.footer-widget .footer-logo {
  margin-bottom: 25px;
}

.footer-widget .footer-logo p {
  font-size: 15px;
  margin: 20px 0 0;
}

.footer-widget .footer-contact .single-footer-contact {
  display: flex;
  margin-bottom: 15px;
}

.footer-widget .footer-contact .single-footer-contact:Last-child {
  margin-bottom: 0;
}

.footer-widget .footer-contact .single-footer-contact .footer-contact-icon {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-contact .single-footer-contact .footer-contact-icon {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget .footer-contact .single-footer-contact .footer-contact-icon {
    margin-right: 12px;
  }
}

.footer-widget .footer-contact .single-footer-contact .footer-contact-icon i {
  font-size: 20px;
  color: #333;
  display: inline-block;
  text-align: center;
  transition: all .3s ease 0s;
}

.footer-widget .footer-contact .single-footer-contact .footer-contact-content p {
  font-size: 15px;
}

.footer-widget .footer-list ul li {
  margin-bottom: 21px;
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-list ul li {
    margin-bottom: 12px;
  }
}

.footer-widget .footer-list ul li a {
  font-size: 15px;
  display: block;
}

.footer-widget .footer-list ul li:last-child {
  margin-bottom: 0px;
}

.footer-widget .subscribe-style p {
  font-size: 15px;
}

.footer-widget .subscribe-style .subscribe-form .mc-form {
  position: relative;
}

.footer-widget .subscribe-style .subscribe-form .mc-form > input {
  background: #fff;
  line-height: 32px;
  padding: 10px 130px 10px 20px;
  margin: 0;
  border: 2px solid rgba(51, 51, 51, 0.1);
  font-size: 14px;
  height: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form > input {
    padding: 10px 120px 10px 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form > input {
    padding: 10px 112px 10px 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form > input {
    padding: 10px 92px 10px 10px;
  }
}

.footer-widget .subscribe-style .subscribe-form .mc-form .mc-news {
  display: none;
}

.footer-widget .subscribe-style .subscribe-form .mc-form .clear {
  position: absolute;
  right: 0;
  top: 1px;
}

.footer-widget .subscribe-style .subscribe-form .mc-form .clear input {
  color: #fff;
  background-color: #333;
  width: 120px;
  text-transform: uppercase;
  font-size: 12px;
  border: none;
  font-weight: 700;
  height: auto;
  padding: 15px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form .clear input {
    width: 108px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form .clear input {
    width: 108px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form .clear input {
    width: 90px;
  }
}

.footer-widget .subscribe-style .subscribe-form .mc-form .clear input:hover {
  background-color: #e32222;
}

.footer-widget .subscribe-style .subscribe-form.subscribe-form-2 .mc-form > input {
  padding: 10px 115px 10px 15px;
  line-height: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .subscribe-style .subscribe-form.subscribe-form-2 .mc-form > input {
    padding: 10px 92px 10px 10px;
  }
}

.footer-widget .subscribe-style .subscribe-form.subscribe-form-2 .clear {
  top: 2px;
}

.footer-widget .subscribe-style .subscribe-form.subscribe-form-2 .clear input {
  width: 100px;
  padding: 11px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .subscribe-style .subscribe-form.subscribe-form-2 .clear input {
    width: 88px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.pl-50 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.pl-50 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.pl-50 {
    padding-left: 0;
  }
  .footer-widget.pl-20 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top.pb-60 {
    padding-bottom: 10px;
  }
}

/*-------- 12. Feature style ---------*/
.feature-wrap {
  display: flex;
  align-items: center;
  background-color: #f4f6f8;
  padding: 40px 40px;
  border: 2px solid transparent;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap {
    padding: 25px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap {
    padding: 30px 20px;
  }
}

.feature-wrap .feature-img {
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap .feature-img {
    margin-right: 15px;
    flex: 0 0 40px;
  }
  .feature-wrap .feature-img img {
    width: 100%;
  }
}

.feature-wrap .feature-content h5 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap .feature-content h5 {
    font-size: 14px;
  }
}

.feature-wrap:hover {
  background-color: transparent;
  border: 2px solid #f4f6f8;
}

/*-------- 13. Brand logo style ---------*/
.brand-logo-active.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.brand-logo-active .single-brand-logo {
  text-align: center;
}

/*-------- 14. Video style ---------*/
.video-bg-img {
  position: relative;
}

.video-bg-img::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: .3;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-bg-img .video-content {
  position: relative;
  z-index: 9;
}

.video-bg-img .video-content h2 {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  font-family: "Meddon", cursive;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .video-bg-img .video-content h2 {
    font-size: 30px;
  }
}

.video-bg-img .video-content .video-icon a {
  font-size: 50px;
  color: #fff;
}

.video-bg-img .video-content .video-icon a:hover {
  color: #e32222;
}

/*-------- 15. Breadcrumb style ---------*/
.breadcrumb-content .breadcrumb-title h2 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 5px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content .breadcrumb-title h2 {
    font-size: 23px;
    margin: 0 0 2px;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  margin-right: 22px;
  text-transform: capitalize;
  position: relative;
}

.breadcrumb-content ul li:last-child {
  margin-right: 0;
}

.breadcrumb-content ul li::before {
  position: absolute;
  width: 15px;
  height: 1px;
  background-color: #1f2226;
  content: "";
  right: -21px;
  top: 13px;
  z-index: 99;
  transform: rotate(115deg);
}

.breadcrumb-content ul li:last-child::before {
  display: none;
}

.breadcrumb-content ul li a {
  font-weight: 600;
}

.breadcrumb-content ul li a:hover {
  color: #e32222;
}

/*-------- 16. Sidebar style ---------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-style.mr-30 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-style {
    margin-top: 30px;
  }
  .sidebar-style.mr-30 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-style {
    margin-top: 30px;
  }
  .sidebar-style.mr-30 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-style.ml-30 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-style {
    margin-top: 30px;
  }
  .sidebar-style.ml-30 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-style {
    margin-top: 40px;
  }
  .sidebar-style.ml-30 {
    margin-left: 0;
  }
}

.sidebar-widget h4.pro-sidebar-title {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}

.sidebar-widget .sidebar-widget-list ul li {
  align-items: center;
  position: relative;
  padding: 0px 0 18px;
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding: 0px 0 0px;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left {
  position: relative;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: auto;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark {
  background-color: #ff3535;
  border: 2px solid #ff3535;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark::after {
  display: block;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 2px solid #888888;
  border-radius: 3px;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sidebar-widget .sidebar-widget-list ul li a {
  margin-left: 30px;
  display: block;
}

.sidebar-widget .sidebar-widget-list ul li a span {
  background-color: #eeeeee;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  border-radius: 50px;
  float: right;
  transition: all .3s ease 0s;
  font-size: 13px;
}

.sidebar-widget .sidebar-widget-list ul li a:hover span {
  color: #fff;
  background-color: #e32222;
}

.sidebar-widget .sidebar-widget-archive ul li {
  margin-bottom: 10px;
  border: 1px solid #dee0e4;
}

.sidebar-widget .sidebar-widget-archive ul li a {
  padding: 12px 20px;
  display: block;
}

.sidebar-widget .sidebar-widget-tag ul li {
  display: inline-block;
  margin: 0 10px 15px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-tag ul li {
    margin: 0 5px 15px 0;
  }
}

.sidebar-widget .sidebar-widget-tag ul li a {
  display: inline-block;
  background-color: #f6f6f6;
  padding: 10px 17px 11px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-tag ul li a {
    padding: 9px 13px 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-widget .sidebar-widget-tag ul li a {
    padding: 9px 14px 12px;
  }
}

.sidebar-widget .sidebar-widget-tag ul li a:hover {
  background-color: #e32222;
  color: #fff;
}

.price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

.price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #ff3535 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 15px;
  margin-left: 0;
  width: 15px;
  border: 4px solid #ff3535;
}

.pro-sidebar-search .pro-sidebar-search-form {
  position: relative;
}

.pro-sidebar-search .pro-sidebar-search-form input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  height: 43px;
  padding: 2px 55px 2px 18px;
}

.pro-sidebar-search .pro-sidebar-search-form button {
  background: transparent none repeat scroll 0 0;
  border-color: #a1a5aa;
  border-image: none;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  padding: 3px 15px 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000;
  transition: all .3s ease 0s;
  font-size: 18px;
}

.pro-sidebar-search .pro-sidebar-search-form button i {
  position: relative;
  top: -2px;
}

.pro-sidebar-search .pro-sidebar-search-form button:hover {
  color: #e32222;
}

.single-sidebar-blog {
  border-bottom: 1px solid #dee0e4;
  display: flex;
  margin: 0 0 20px;
  padding: 0 0 20px;
  align-items: center;
}

.single-sidebar-blog:last-child {
  border-bottom: none;
}

.single-sidebar-blog .sidebar-blog-img {
  flex: 0 0 70px;
  margin: 0 20px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-sidebar-blog .sidebar-blog-img {
    margin: 0 8px 0 0;
  }
}

.single-sidebar-blog .sidebar-blog-img img {
  width: 100%;
}

.single-sidebar-blog .sidebar-blog-content span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.single-sidebar-blog .sidebar-blog-content h4 {
  font-size: 14px;
  margin: 5px 0 0;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-sidebar-blog .sidebar-blog-content h4 {
    font-size: 14px;
  }
}

/*-------- 17. Shop page style ---------*/
.shop-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar {
    display: flex;
  }
}

.shop-top-bar .select-shoing-wrap {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .select-shoing-wrap {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .select-shoing-wrap {
    display: flex;
  }
}

.shop-top-bar .select-shoing-wrap .shop-select {
  margin-right: 50px;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .select-shoing-wrap .shop-select {
    margin-right: 112px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .select-shoing-wrap .shop-select {
    margin-right: 50px;
  }
}

.shop-top-bar .select-shoing-wrap .shop-select select {
  font-size: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  min-width: 150px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 0px center;
  text-transform: capitalize;
}

.shop-top-bar .select-shoing-wrap p {
  margin: 0;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .shop-top-bar .select-shoing-wrap p {
    margin: 5px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-top-bar .select-shoing-wrap p {
    margin: 0px;
  }
}

.shop-top-bar .shop-tab a {
  font-size: 24px;
  margin-left: 10px;
}

.shop-top-bar .shop-tab a:first-child {
  margin-left: 0;
}

.shop-top-bar .shop-tab a.active {
  color: #ff3535;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-20 {
    margin-top: 10px;
  }
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 4px;
}

.pro-pagination-style ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 100%;
  color: #e32222;
  box-shadow: 0 0px 12px 0.8px rgba(0, 0, 0, 0.1);
}

.pro-pagination-style ul li a:hover {
  background-color: #e32222;
  color: #fff;
}

.pro-pagination-style ul li a.active {
  background-color: #e32222;
  color: #fff;
  box-shadow: none;
}

.pro-pagination-style ul li a.active:hover {
  background-color: #333;
}

.pro-pagination-style ul li a.prev,
.pro-pagination-style ul li a.next {
  background-color: #f6f6f6;
  color: #e32222;
  font-size: 17px;
  box-shadow: none;
}

.pro-pagination-style ul li a.prev:hover,
.pro-pagination-style ul li a.next:hover {
  background-color: #e32222;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 0px;
  }
}

.shop-list-wrap .shop-list-content h3 {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content h3 {
    font-size: 20px;
    line-height: 20px;
  }
}

.shop-list-wrap .shop-list-content h3 a:hover {
  color: #e32222;
}

.shop-list-wrap .shop-list-content .product-list-price {
  margin: 8px 0 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .shop-list-content .product-list-price {
    margin: 13px 0 19px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .product-list-price {
    margin: 8px 0 5px;
  }
}

.shop-list-wrap .shop-list-content .product-list-price span {
  color: #333;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  font-weight: 500;
}

.shop-list-wrap .shop-list-content .product-list-price span.old {
  text-decoration: line-through;
  font-size: 18px;
  color: #8e8e8e;
  margin-left: 15px;
}

.shop-list-wrap .shop-list-content .product-list-rating {
  margin: 6px 0 0;
}

.shop-list-wrap .shop-list-content .product-list-rating i {
  font-size: 16px;
  color: #ffa900;
  margin: 0 1px 0;
}

.shop-list-wrap .shop-list-content p {
  margin: 15px 0 35px;
  line-height: 28px;
  font-size: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-list-wrap .shop-list-content p {
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content p {
    margin: 0px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-list-wrap .shop-list-content p {
    margin: 12px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-list-wrap .shop-list-content p {
    margin: 15px 0 20px;
  }
}

.shop-list-wrap .shop-list-content .shop-list-btn-wrap {
  display: flex;
}

.shop-list-wrap .shop-list-content .shop-list-btn-wrap .shop-list-cart {
  margin-right: 15px;
}

.shop-list-wrap .shop-list-content .shop-list-btn-wrap .shop-list-cart a {
  background-color: #333;
  padding: 15px 25px;
  font-weight: 600;
}

.shop-list-wrap .shop-list-content .shop-list-btn-wrap .shop-list-wishlist a {
  background-color: #333;
  padding: 11px 19px;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

/*-------- 18. Cart style ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pb-100 {
    padding-bottom: 60px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}

.cart-table-content table {
  border: 1px solid #ebebeb;
}

.cart-table-content table thead > tr {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 14px;
  font-weight: 600;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 21px 35px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 21px 20px 22px;
  }
}

.cart-table-content table tbody > tr {
  border-bottom: 1px solid #ebebeb;
}

.cart-table-content table tbody > tr td.product-thumbnail {
  width: 150px;
}

.cart-table-content table tbody > tr td.product-name {
  width: 435px;
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 15px;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #e32222;
}

.cart-table-content table tbody > tr td.product-price-cart {
  width: 435px;
}

.cart-table-content table tbody > tr td.product-price-cart span {
  color: #333;
  font-weight: 500;
  font-family: "montserrat", sans-serif;
}

.cart-table-content table tbody > tr td.product-quantity {
  width: 435px;
}

.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus {
  display: inline-block;
  height: 40px;
  padding: 0;
  position: relative;
  width: 110px;
}

.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 20px;
  text-align: center;
}

.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .dec.qtybutton {
  border-right: 1px solid #e5e5e5;
  height: 40px;
  left: 0;
  padding-top: 8px;
  top: 0;
}

.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .inc.qtybutton {
  border-left: 1px solid #e5e5e5;
  height: 40px;
  padding-top: 9px;
  right: 0;
  top: 0;
}

.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus input.cart-plus-minus-box {
  color: #333;
  float: left;
  font-size: 14px;
  height: 40px;
  margin: 0;
  width: 110px;
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e1e1e1;
  padding: 0;
  text-align: center;
}

.cart-table-content table tbody > tr td.product-remove {
  width: 100px;
}

.cart-table-content table tbody > tr td.product-remove a {
  font-size: 17px;
  margin: 0 6px;
}

.cart-table-content table tbody > tr td.product-remove a:hover {
  color: #e32222;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #e32222;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 7px 12px;
  text-transform: uppercase;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #333;
}

.cart-table-content table tbody > tr td {
  font-size: 15px;
  padding: 30px 0;
  text-align: center;
  font-weight: 500;
  font-family: "montserrat", sans-serif;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 30px 0 30px 10px;
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 55px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
    padding: 30px 0px 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
  background-color: #f2f2f2;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 40px 17px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 25px 17px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 40px 17px;
    margin: 0 0 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a:hover,
.cart-shiping-update-wrapper .cart-clear > button:hover,
.cart-shiping-update-wrapper .cart-clear > a:hover {
  background-color: #e32222;
  color: #fff;
}

.cart-shiping-update-wrapper .cart-clear > button {
  border: medium none;
  cursor: pointer;
  margin-right: 27px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 15px;
  }
}

.cart-tax,
.discount-code-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 18px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 20px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 18px 50px;
  }
}

.cart-tax .title-wrap,
.discount-code-wrapper .title-wrap {
  position: relative;
}

.cart-tax .title-wrap::before,
.discount-code-wrapper .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}

.cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-right: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-tax .title-wrap h4.cart-bottom-title,
  .discount-code-wrapper .title-wrap h4.cart-bottom-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-tax .title-wrap h4.cart-bottom-title,
  .discount-code-wrapper .title-wrap h4.cart-bottom-title {
    font-size: 17px;
  }
}

.cart-tax .title-wrap .section-bg-gray,
.discount-code-wrapper .title-wrap .section-bg-gray {
  background-color: #f8f9f9;
  position: relative;
  z-index: 99;
}

.cart-tax .tax-wrapper,
.discount-code-wrapper .tax-wrapper {
  margin-top: 22px;
}

.cart-tax .tax-wrapper p,
.discount-code-wrapper .tax-wrapper p {
  margin: 0;
}

.cart-tax .tax-wrapper .tax-select-wrapper,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper {
  margin: 5px 0 0;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select {
  margin: 0 0 26px;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select label,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select label {
  color: #333;
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url("../../assets/images/icon-img/cart.png") no-repeat scroll right 18px center;
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #242424;
  font-size: 13px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select input,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  height: 40px;
}

.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2 {
  background-color: #1f2226;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  padding: 13px 40px 12px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover {
  background-color: #e32222;
}

.cart-tax .discount-code,
.discount-code-wrapper .discount-code {
  margin: 21px 0 0;
}

.cart-tax .discount-code p,
.discount-code-wrapper .discount-code p {
  margin: 0 0 15px;
}

.cart-tax .discount-code form input,
.discount-code-wrapper .discount-code form input {
  background: #fff;
  border: 1px solid #ebebeb;
  height: 40px;
  margin-bottom: 30px;
  padding-left: 10px;
}

.cart-tax .discount-code form button.cart-btn-2,
.discount-code-wrapper .discount-code form button.cart-btn-2 {
  background-color: #1f2226;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  padding: 13px 40px 12px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.cart-tax .discount-code form button.cart-btn-2:hover,
.discount-code-wrapper .discount-code form button.cart-btn-2:hover {
  background-color: #e32222;
}

.grand-totall {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-totall {
    padding: 45px 18px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-totall {
    padding: 45px 20px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-totall {
    margin-top: 30px;
  }
}

.grand-totall .title-wrap {
  position: relative;
}

.grand-totall .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}

.grand-totall .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-right: 18px;
}

.grand-totall .title-wrap .section-bg-gary-cart {
  background-color: #f9f9f9;
  position: relative;
  z-index: 9;
}

.grand-totall h5 {
  font-size: 14px;
  margin: 36px 0 27px;
  font-weight: 600;
}

.grand-totall h5 span {
  float: right;
  font-size: 18px;
  font-weight: 500;
}

.grand-totall .total-shipping {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin: 0 0 27px;
  padding: 28px 0;
}

.grand-totall .total-shipping h5 {
  font-size: 14px;
  margin: 0;
}

.grand-totall .total-shipping ul {
  padding: 19px 0 0 0px;
}

.grand-totall .total-shipping ul li {
  color: #242424;
  list-style: outside none none;
  margin: 0 0 6px;
}

.grand-totall .total-shipping ul li:last-child {
  margin: 0 0 0px;
}

.grand-totall .total-shipping ul li input {
  background: #e9e9e9 none repeat scroll 0 0;
  border: 1px solid #d7d7d7;
  border-radius: 5px !important;
  color: #626262;
  cursor: pointer;
  height: 13px;
  margin-right: 10px;
  padding: 0;
  position: relative;
  top: 2px;
  width: 13px;
}

.grand-totall .total-shipping ul li span {
  float: right;
}

.grand-totall h4.grand-totall-title {
  color: #e32222;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 25px;
}

.grand-totall h4.grand-totall-title span {
  float: right;
}

.grand-totall a {
  background-color: #1f2226;
  color: #fff;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase;
}

.grand-totall a:hover {
  background-color: #e32222;
}

@media only screen and (max-width: 767px) {
  .discount-code-wrapper {
    margin: 30px 0;
  }
}

/*-------- 19. Login register style ---------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
}

.login-register-wrapper .login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-register-tab-list a:last-child::before {
  display: none;
}

.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #e32222;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-size: 14px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
  float: right;
  font-size: 15px;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
  color: #e32222;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button {
  background-color: #f2f2f2;
  border: medium none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 30px 14px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
  background-color: #e32222;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .login-register-area.pb-100 {
    padding-bottom: 60px;
  }
}

/*-------- 20. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: 600;
  font-size: 13px;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #e32222;
  border-color: #e32222;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 600;
}

.myaccount-content .welcome a:hover {
  color: #e32222;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #e32222;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #e32222;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #e32222;
  font-size: 14px;
  padding: 20px 0;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 21. Product details style ---------*/
.product-details-img {
  position: relative;
}

.product-details-img .zoompro-span {
  position: relative;
}

.product-details-img .zoompro-span .zoompro {
  width: 100%;
}

.product-details-img .zoompro-span > span {
  background-color: #e32222;
  color: #fff;
  left: 20px;
  line-height: 1;
  padding: 3px 10px 5px;
  position: absolute;
  top: 20px;
  z-index: 99;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.product-details-img .product-dec-slider {
  padding: 0 50px 0 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider {
    padding: 0 30px 0 45px;
    margin-bottom: 20px;
  }
}

.product-details-img .product-dec-slider .product-dec-icon {
  color: #1f2226;
  display: inline-block;
  font-size: 13px;
  left: 30px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 100%;
  border: 1px solid #e1e2e2;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider .product-dec-icon {
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider .product-dec-icon {
    left: 0px;
  }
}

.product-details-img .product-dec-slider .product-dec-icon:hover {
  background-color: #e32222;
  border: 1px solid #e32222;
  color: #ffffff;
}

.product-details-img .product-dec-slider .product-dec-icon.product-dec-next {
  left: auto;
  right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider .product-dec-icon.product-dec-next {
    right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider .product-dec-icon.product-dec-next {
    right: 0px;
  }
}

.product-details-img .product-dec-slider-2 {
  display: inline-block;
  padding: 0;
  width: 20%;
  margin: 12px 0 0px;
  float: left;
}

@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider-2 {
    width: 30%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-img .product-dec-slider-2 {
    width: 20%;
  }
}

.product-details-img .product-dec-slider-2 .slick-slide {
  margin-bottom: 3px;
  margin-top: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-img .product-dec-slider-2 .slick-slide {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider-2 .slick-slide {
    margin-bottom: 10px;
  }
}

.product-details-img .product-dec-slider-2 .slick-slide img {
  width: 100%;
}

.product-details-img .product-dec-slider-2 .product-dec-icon {
  cursor: pointer;
  display: inline-block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 99;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  border: 1px solid #e1e2e2;
  text-align: center;
  font-size: 13px;
  color: #1f2226;
}

.product-details-img .product-dec-slider-2 .product-dec-icon:hover {
  background-color: #e32222;
  border: 1px solid #e32222;
  color: #ffffff;
}

.product-details-img .product-dec-slider-2 .product-dec-icon.product-dec-prev {
  top: -13px;
}

.product-details-img .product-dec-slider-2 .product-dec-icon.product-dec-next {
  bottom: -7px;
}

.product-details-img .product-zoom-right {
  display: inline-block;
  position: relative;
  width: 80%;
  float: left;
}

@media only screen and (max-width: 767px) {
  .product-details-img .product-zoom-right {
    width: 70%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-img .product-zoom-right {
    width: 80%;
  }
}

.description-review-wrapper {
  border: 1px solid #e8e8e8;
  padding: 30px 30px 37px;
}

.description-review-wrapper.description-sticky {
  padding: 0;
  border: none;
  margin: 44px 0 58px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper.description-sticky {
    margin: 30px 0 30px;
  }
}

.description-review-wrapper.description-sticky .description-review-topbar {
  padding: 0 0 41px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper.description-sticky .description-review-topbar {
    padding: 0 0 20px;
  }
}

.description-review-wrapper.description-sticky .description-review-bottom .ratting-form-wrapper .ratting-form {
  margin-right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-wrapper {
    padding: 30px 20px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper {
    padding: 30px 15px 50px;
  }
}

.description-review-wrapper .description-review-topbar {
  padding: 0 0 24px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .description-review-topbar {
    padding: 0 0 15px;
  }
}

.description-review-wrapper .description-review-topbar a {
  color: #333;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 1;
  position: relative;
  padding: 0 0 11px;
  margin: 0 32px 0 0;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-wrapper .description-review-topbar a {
    margin: 0 14px 0 0;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .description-review-topbar a {
    margin: 0 12px 10px 0;
    font-size: 15px;
    padding: 0 0 6px;
  }
  .description-review-wrapper .description-review-topbar a:last-child {
    margin: 0 12px 5px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .description-review-wrapper .description-review-topbar a {
    margin: 0 32px 0 0;
    font-size: 16px;
  }
}

.description-review-wrapper .description-review-topbar a.active {
  color: #e32222;
  border-bottom: 1px solid #e32222;
}

.description-review-wrapper .description-review-bottom .product-description-wrapper p {
  color: #333;
  font-size: 14px;
  margin: 0 0 19px;
  line-height: 26px;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-img {
  flex: 0 0 70px;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-img img {
  width: 100%;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content {
    margin-left: 10px;
  }
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content p {
  font-size: 14px;
  font-style: italic;
  margin: 0 0 42px;
  color: #333;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap .review-name {
    margin: 0 0 10px;
  }
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap .review-name h4 {
  font-size: 13px;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  position: relative;
  font-weight: 500;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap .review-name h4:after {
  position: absolute;
  top: -25px;
  left: 0;
  background-color: #343538;
  width: 60px;
  height: 1px;
  content: "";
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review .review-content .review-top-wrap .review-rating i {
  color: #e7a415;
  font-size: 12px;
  margin-left: 2px;
}

.description-review-wrapper .description-review-bottom .review-wrapper .single-review:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper {
  margin: 40px 0 0;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper > span {
  font-size: 14px;
  color: #333;
  display: block;
  line-height: 1;
  margin: 0 0 9px;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper p {
  color: #333;
  font-size: 14px;
  margin: 0;
  font-style: italic;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper p span {
  color: red;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .star-box-wrap {
  display: flex;
  margin: 22px 0 20px;
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper .description-review-bottom .ratting-form-wrapper .star-box-wrap {
    display: block;
  }
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .star-box-wrap .single-ratting-star {
  margin-right: 20px;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .star-box-wrap .single-ratting-star i {
  color: #8a8a8a;
  font-size: 12px;
  transition: all .3s ease 0s;
  cursor: pointer;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .rating-form-style label {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 8px;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .rating-form-style label span {
  color: red;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .rating-form-style input {
  border: 1px solid #e8e8e8;
  background: transparent;
  height: 45px;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .rating-form-style input:focus {
  border: 1px solid #343538;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  border: 1px solid #e8e8e8;
  background: transparent;
  height: 200px;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .rating-form-style textarea:focus {
  border: 1px solid #343538;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .form-submit input {
  padding: 10px 40px;
  text-transform: uppercase;
  width: auto;
  color: #fff;
  background-color: #e32222;
  font-weight: 600;
  border: none;
}

.description-review-wrapper .description-review-bottom .ratting-form-wrapper .ratting-form form .form-submit input:hover {
  background-color: #343538;
}

.description-review-wrapper .description-review-bottom .product-anotherinfo-wrapper ul li {
  color: #333;
  font-size: 14px;
  list-style: outside none none;
  margin: 0 0 13px;
}

.description-review-wrapper .description-review-bottom .product-anotherinfo-wrapper ul li span {
  color: #333;
  display: inline-block;
  font-weight: 600;
  margin: 0 26px 0 0;
  min-width: 85px;
  font-size: 14px;
}

.pro-dec-banner a img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .pro-dec-banner {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.ml-30 {
    margin-left: 0;
  }
  .product-details-content.mr-30 {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.ml-30 {
    margin-left: 0;
  }
  .product-details-content.mr-30 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.ml-30 {
    margin-left: 0;
  }
  .product-details-content.mr-30 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-area.pb-95 {
    padding-bottom: 38px;
  }
  .product-details-area.pb-70 {
    padding-bottom: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-mrg {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-mrg {
    margin-top: 30px;
  }
}

.slick-vertical .slick-slide {
  border: 0px solid transparent;
}

.box-slider-active:hover.nav-style-1.owl-carousel > .owl-nav div {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .sidebar-active .product-details-content {
    margin-top: 0;
  }
}

.sidebar-active.col-lg-6 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-active.col-lg-6.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-active.col-lg-6.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

/*-------- 22. Blog details style ---------*/
.blog-details-top .blog-details-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-img.mt-50 {
    margin-top: 30px;
  }
  .blog-details-top .blog-details-img.mb-45 {
    margin-bottom: 25px;
  }
}

.blog-details-top .blog-details-content > span {
  font-style: italic;
  color: #666;
  font-size: 15px;
}

.blog-details-top .blog-details-content h3 {
  font-weight: 700;
  font-size: 22px;
  margin: 7px 0 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-top .blog-details-content h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content h3 {
    font-size: 18px;
    line-height: 27px;
  }
}

.blog-details-top .blog-details-content .blog-dec-meta {
  margin: 0 0 20px;
}

.blog-details-top .blog-details-content .blog-dec-meta span {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 15px;
  color: #666;
}

.blog-details-top .blog-details-content .blog-dec-meta span i {
  font-size: 18px;
  padding-right: 5px;
}

.blog-details-top .blog-details-content p {
  line-height: 28px;
  font-size: 15px;
  margin: 0 0 20px;
}

.blog-details-top .blog-details-content blockquote {
  padding: 40px 40px 63px;
  border: 1px solid #e8e8e8;
  text-align: center;
  position: relative;
  margin: 50px 0 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-top .blog-details-content blockquote {
    padding: 40px 25px 63px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content blockquote {
    padding: 20px 20px 63px;
    margin: 30px 0 50px;
  }
}

.blog-details-top .blog-details-content blockquote p {
  font-size: 20px;
  color: #333;
  line-height: 35px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content blockquote p {
    font-size: 18px;
    line-height: 30px;
  }
}

.blog-details-top .blog-details-content blockquote span {
  position: absolute;
  bottom: -26px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  padding: 14px 20px;
  text-transform: uppercase;
  font-style: italic;
  color: #666;
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content blockquote span {
    padding: 8px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper.mt-55 {
    margin-top: 25px;
  }
}

.blog-comment-wrapper .blog-dec-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .blog-dec-title {
    font-size: 18px;
  }
}

.blog-comment-wrapper .single-comment-wrapper {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper {
    display: block;
  }
  .blog-comment-wrapper .single-comment-wrapper.mt-35 {
    margin-top: 20px;
  }
  .blog-comment-wrapper .single-comment-wrapper.mt-50 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper {
    display: flex;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
  flex: 0 0 120px;
  margin-right: 28px;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
    margin-right: 28px;
    margin-bottom: 20px;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
    width: auto;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content h4 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  text-transform: capitalize;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content span {
  display: block;
  font-size: 14px;
  margin: 2px 0 8px;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content p {
  font-size: 14px;
  margin-bottom: 10px;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content .blog-details-btn a {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper.ml-120 {
    margin-left: 0;
  }
}

.next-previous-post {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding: 18px 0 17px;
  display: flex;
  justify-content: space-between;
}

.next-previous-post a {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
}

.next-previous-post a i {
  font-size: 16px;
  position: relative;
  top: 1px;
}

@media only screen and (max-width: 767px) {
  .next-previous-post.mt-50 {
    margin-top: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper.mt-50 {
    margin-top: 30px;
  }
}

.blog-reply-wrapper .blog-dec-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper .blog-dec-title {
    font-size: 18px;
  }
}

.blog-reply-wrapper .blog-form {
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper .blog-form {
    margin-top: 20px;
  }
}

.blog-reply-wrapper .blog-form .leave-form input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  box-shadow: none;
  height: 50px;
  margin-bottom: 30px;
  padding: 2px 20px 2px 20px;
  color: #333;
}

.blog-reply-wrapper .blog-form .text-leave textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  box-shadow: none;
  height: 250px;
  margin-bottom: 20px;
  padding: 20px 20px 2px 20px;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper .blog-form .text-leave textarea {
    height: 180px;
  }
}

.blog-reply-wrapper .blog-form .text-leave input {
  background-color: #e32222;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: inherit;
  margin-top: 10px;
  padding: 14px 30px 13px;
  width: inherit;
  font-size: 14px;
}

.blog-reply-wrapper .blog-form .text-leave input:hover {
  background-color: #333;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper.ml-20 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper.ml-20 {
    margin-left: 0;
  }
}

/*-------- 23. Checkout style ---------*/
.customer-zone > p {
  font-size: 14px;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}

.customer-zone > p:before {
  content: "\f17c";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: LineAwesome;
  left: 15px;
  top: 15px;
  font-size: 15px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}

.checkout-login-info p {
  font-size: 14px;
  margin: 0 0 12px;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
}

.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  border: 1px solid #aaa;
  font-size: 13px;
  padding: 10px 30px 9px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.checkout-login-info form .button-remember-wrap button:hover {
  background-color: #e32222;
  color: #fff;
  border: 1px solid #e32222;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  font-size: 14px;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  font-size: 14px;
}

.checkout-login-info .checkout-login-social span {
  font-size: 14px;
  margin: 0 0 13px;
  display: block;
}

.checkout-login-info .checkout-login-social ul li {
  display: inline-block;
  margin: 0 14px 0 0;
}

.checkout-login-info .checkout-login-social ul li:last-child {
  margin: 0 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li {
    margin: 0 5px 0 0;
  }
}

.checkout-login-info .checkout-login-social ul li a {
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  float: left;
  font-size: 13px;
  padding: 20px;
  padding: 24px 106px 25px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 24px 70px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 15px 13px 16px;
  }
}

.checkout-login-info .checkout-login-social ul li a:hover {
  background-color: #e32222;
  color: #fff;
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}

.checkout-login-info2 ul li {
  display: inline-block;
}

.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info2 ul li a:hover {
  color: #e32222;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}

.checkout-login-info3 form input[type="text"] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info3 form input[type="text"]:focus {
  border: 1px solid #1f2226;
}

.checkout-login-info3 form input[type="submit"] {
  background: #1f2226 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 16px;
  }
}

.checkout-login-info3 form input[type="submit"]:hover {
  background-color: #e32222;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 55px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin-bottom: 40px;
  }
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 14px;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select select {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #333;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
  cursor: pointer;
}

.billing-info-wrap .billing-select select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  color: #333;
  font-size: 12px;
}

.billing-info-wrap .checkout-account input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
  color: #333;
}

.billing-info-wrap .checkout-account span {
  color: #333;
  font-weight: 400;
  margin: 0 0 0 12px;
  font-size: 14px;
  text-transform: uppercase;
}

.billing-info-wrap .checkout-account-toggle label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}

.billing-info-wrap .checkout-account-toggle input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  padding: 2px 20px;
}

.billing-info-wrap .checkout-account-toggle input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .different-address {
  display: none;
}

.billing-info-wrap .additional-info-wrap {
  margin: 22px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #333;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #333;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-50 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 45px;
  text-transform: uppercase;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #000;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  font-size: 14px;
  padding: 0 0 7px;
  text-transform: uppercase;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 14px;
  text-align: right;
  color: #333;
  text-transform: capitalize;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 17px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment.sin-payment-3 input {
  margin-top: 18px;
}

.your-order-area .Place-order a {
  background-color: #e32222;
  display: block;
  cursor: pointer;
  padding: 17px 50px 18px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #333;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 24. Contact style ---------*/
.contact-info-area h2 {
  font-weight: 600;
  margin: 0 0 15px;
  font-size: 35px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  .contact-info-area h2 {
    margin: 0 0 7px;
    font-size: 27px;
  }
}

.contact-info-area p {
  margin: 0 0 0px;
  font-size: 15px;
  color: #1f2226;
  line-height: 26px;
}

.contact-info-area .contact-info-wrap {
  margin: 60px 0 0;
}

@media only screen and (max-width: 767px) {
  .contact-info-area .contact-info-wrap {
    margin: 20px 0 30px;
  }
}

.contact-info-area .contact-info-wrap .single-contact-info {
  display: flex;
  margin: 0 0 37px;
}

.contact-info-area .contact-info-wrap .single-contact-info:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .contact-info-area .contact-info-wrap .single-contact-info {
    margin: 0 0 20px;
  }
}

.contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon {
  flex: 0 0 62px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon {
    flex: 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon {
    flex: 0 0 38px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon {
    flex: 0 0 55px;
  }
}

.contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon i {
  font-size: 36px;
  color: #e32222;
  position: relative;
  top: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon i {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-icon i {
    font-size: 28px;
    top: 4px;
  }
}

.contact-info-area .contact-info-wrap .single-contact-info .contact-info-content p {
  margin: 0;
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-content p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area .contact-info-wrap .single-contact-info .contact-info-content p {
    font-size: 15px;
  }
}

.contact-from {
  box-shadow: 0 25px 45px rgba(1, 2, 2, 0.12);
  padding: 50px;
  margin-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-from {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-from {
    margin-left: 0px;
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-from {
    margin-left: 0px;
    padding: 30px 20px;
  }
}

.contact-from input, .contact-from textarea {
  background: transparent;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  height: 50px;
  padding: 2px 24px;
  margin-bottom: 15px;
  color: #1f2226;
}

.contact-from textarea {
  height: 120px;
  padding: 16px 24px 2px;
}

.contact-from button {
  border: none;
  padding: 0;
  background-color: transparent;
  text-align: center;
  color: #fff;
  background-color: #1f2226;
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 10px;
}

@media only screen and (max-width: 767px) {
  .contact-from button {
    padding: 13px 10px;
  }
}

.contact-from button:hover {
  background-color: #e32222;
}

.contact-map #map {
  height: 500px;
}

@media only screen and (max-width: 767px) {
  .contact-map #map {
    height: 300px;
  }
}

/*-------- 25. Compare style ---------*/
.compare-table .table tbody tr td {
  text-align: center;
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 115px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 220px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #e32222;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 5px;
  font-family: "montserrat", sans-serif;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #e32222;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 14px;
  font-weight: 400;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #e32222;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #e7a415;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 26. About us style ---------*/
.about-content {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .about-content {
    margin-right: 0px;
  }
}

.about-content h3 {
  font-weight: 700;
  font-size: 32px;
  text-transform: capitalize;
  margin: 0 0 20px;
}

.about-content h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 20px;
}

.about-content p {
  font-size: 15px;
  margin-bottom: 23px;
  line-height: 28px;
}

.about-img img {
  width: 100%;
}

.team-wrapper {
  position: relative;
}

.team-wrapper .team-img {
  position: relative;
  overflow: hidden;
}

.team-wrapper .team-img::before {
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.9) 100%) repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  transform: translate3d(0px, 50%, 0px);
}

.team-wrapper .team-img img {
  width: 100%;
}

.team-wrapper .team-img .team-action {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}

.team-wrapper .team-img .team-action a {
  font-size: 14px;
  margin: 0 3px;
  display: inline-block;
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid transparent;
}

.team-wrapper .team-img .team-action a.facebook {
  background-color: #3b5999;
}

.team-wrapper .team-img .team-action a.facebook:hover {
  color: #3b5999;
  border: 1px solid #3b5999;
  background-color: transparent;
}

.team-wrapper .team-img .team-action a.twitter {
  background-color: #55acee;
}

.team-wrapper .team-img .team-action a.twitter:hover {
  color: #55acee;
  border: 1px solid #55acee;
  background-color: transparent;
}

.team-wrapper .team-img .team-action a.instagram {
  background-color: #e4405f;
}

.team-wrapper .team-img .team-action a.instagram:hover {
  border: 1px solid #e4405f;
  color: #e4405f;
  background-color: transparent;
}

.team-wrapper .team-content {
  box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
  padding: 16px 5px 18px;
}

.team-wrapper .team-content h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 4px;
  text-transform: capitalize;
}

.team-wrapper .team-content span {
  font-size: 15px;
  font-style: italic;
}

.team-wrapper:hover .team-img::before {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.team-wrapper:hover .team-action {
  opacity: 1;
  visibility: visible;
}

/*-------- 27. 404 style ---------*/
.error-content img {
  width: 100%;
}

.error-content h2 {
  font-weight: 600;
  margin: 50px 0 40px;
  font-size: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content h2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h2 {
    font-size: 20px;
    margin: 30px 0 25px;
  }
}

.error-content .error-btn {
  margin: 0 0 30px;
}

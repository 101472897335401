/*-------- 2. Header style ---------*/

.main-header-wrap { 
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
}

.header-small-mobile {
    display: none;
    padding: 25px 0;
    @media #{$md-layout} {
        display: block;
    }
    @media #{$xs-layout} {
        display: block;
    }
}



.section-padding-1 {
    .container-fluid {
        padding: 0 130px;
        @media #{$xx-layout} {
            padding: 0 100px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
    } 
}
.section-padding-2 {
    .container-fluid {
        padding: 0 70px;
        @media #{$xx-layout} {
            padding: 0 70px;
        }
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
    } 
}

.sticky-bar.stick {
	animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
	background-color: #fff;
	border-bottom: 0 solid #4a90e2;
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	left: 0;
	transition: all .3s ease 0s;
}

.logo {
    transition: all .3s ease 0s;
}
.main-menu {
    display: flex;
    justify-content: center;
    > nav {
        > ul {
            > li {
                display: inline-block;
                margin: 0 18px;
                position: relative;
                @media #{$xl-layout} {
                    margin: 0 16px;
                }
                @media #{$lg-layout} {
                    margin: 0 12px;
                }
                &.angle-shape::before {
                    border-bottom: 16px solid #fff;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    content: "";
                    height: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    bottom: 0px;
                    width: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.5s ease 0s;
                    z-index: 9999;
                }
                > a {
                    font-size: 14px;
                    display: inline-block;
                    line-height: 108px;
                    text-transform: uppercase;
                    color: #000000;
                    font-weight: 500;
                    font-family: $montserrat;
                    letter-spacing: .8px;
                }
                ul.submenu {
                    background: #fff none repeat scroll 0 0;
                    box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                    display: block;
                    left: 0;
                    padding: 22px 30px 24px;
                    position: absolute;
                    top: 100%;
                    transform: rotateX(90deg);
                    transform-origin: center top 0;
                    transition: all 0.5s ease 0s;
                    visibility: hidden;
                    width: 190px;
                    z-index: 999;
                    li {
                        margin-bottom: 16px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                        a {
                            font-weight: 300;
                            font-size: 14px;
                            color: #000000;
                            letter-spacing: .2px;
                            display: block;
                            text-transform: capitalize;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                }
                ul.mega-menu {
                    background-color: #fff;
                    display: block;
                    left: -273px;
                    padding: 23px 30px 62px 0;
                    position: absolute;
                    text-align: left;
                    top: 100%;
                    transform: rotateX(90deg);
                    transform-origin: center top 0;
                    transition: all 0.5s ease 0s;
                    visibility: hidden;
                    width: 972px;
                    z-index: 999;
                    box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                    @media #{$lg-layout} {
                        width: 900px;
                    }
                    > li {
                        display: inline-block;
                        float: left;
                        padding-left: 30px;
                        padding-right: 0;
                        width: 25%;
                        a.menu-title {
                            color: #000000;
                            font-size: 16px;
                            letter-spacing: .2px;
                            display: block;
                            border-bottom: 1px solid #cccccc;
                            padding-bottom: 18px;
                        }
                        ul {
                            margin-top: 23px;
                            li {
                                margin-bottom: 19px;
                                &:last-child {
                                    margin-bottom: 0px;
                                }
                                a {
                                    font-weight: 300;
                                    font-size: 14px;
                                    color: #000000;
                                    letter-spacing: .2px;
                                    display: block;
                                    text-transform: capitalize;
                                    &:hover {
                                        color: $theme-color;
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover > ul.mega-menu {
                    transform: rotateX(0deg);
                    visibility: visible;
                }
                &:hover > ul.submenu{
                    transform: rotateX(0deg);
                    visibility: visible;
                }
                &:hover a {
                    color: $theme-color;
                }
                &:hover.angle-shape::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        } 
    }
}


.header-right-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 38px 0;
    .same-style {
        > a {
            display: inline-block;
            font-size: 25px;
        }
        &.cart-wrap {
            a {
                position: relative;
                span {
                    position: absolute;
                    font-size: 11px;
                    height: 18px;
                    line-height: 17px;
                    color: #fff;
                    display: block;
                    top: -8px;
                    right: -5px;
                    min-width: 18px;
                    text-align: center;
                    padding: 0;
                    border-radius: 50%;
                    background-color: #e32222;
                    font-weight: bold;
                }
            }
        }
    }
    .setting-wrap {
        .setting-content {
            background: #fff none repeat scroll 0 0;
            padding: 26px 30px 32px;
            position: absolute;
            right: 0;
            top: 100%;
            width: 300px;
            z-index: 9999;
            box-shadow: 0 0 20px .4px rgba(0,0,0,.1);
            max-height: 636px;
            overflow-y: auto;
            display: none;
            @media #{$xx-layout} {
                max-height: 510px;
            }
            @media #{$xl-layout} {
                max-height: 400px;
            }
            @media #{$lg-layout} {
                max-height: 400px;
            }
            ul {
                li {
                    padding-bottom: 30px;
                    &:last-child {
                        padding-bottom: 0px;
                    }
                    h4 {
                        font-size: 16px;
                        margin: 0;
                        color: #000000;
                        padding-bottom: 19px;
                        border-bottom: 1px solid #cccccc;
                        font-family: $montserrat;
                        font-weight: 500;
                    }
                    ul {
                        padding-top: 18px;
                        li {
                            padding-bottom: 25px;
                            &:last-child {
                                padding-bottom: 0px;
                            }
                            a {
                                letter-spacing: .2px;
                                color: #000000;
                                font-weight: 300;
                                display: block;
                                line-height: 1;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.search-content-wrap {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	background-color: rgba(0,0,0,.97);
	text-align: center;
	transition: all ease-in-out .25s;
	z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.search-visible {
        visibility: visible;
	    opacity: 1;
        & .search-content {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
    > .search-close {
        font-size: 40px;
        display: block;
        position: absolute;
        top: 20%;
        right: 20%;
        line-height: 40px;
        height: 40px;
        transition: all .3s;
        color: #fff !important;
        &:hover {
            transform: rotate(90deg);
        }
        i {
            &:hover {
                color: $theme-color;
            }
        }
        
    }
    & .search-content {
        display: inline-block;
        max-width: 90%;
        text-align: center;
        font-size: 18px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out .3s;
        transform: scale(.9);
        p{
           font-size: 18px;
            margin: 0 0 15px;
            text-transform: uppercase;
            color: rgba(255,255,255,.8);
            font-weight: 400;
            font-family: $montserrat;
        }
        .search-form {
            position: relative;
            input {
                width: 800px;
                background-color: transparent;
                box-shadow: 0 3px 0 0 rgba(255,255,255,.1);
                border: 0;
                text-align: center;
                font-size: 27px;
                padding: 34px 40px 25px 36px;
                color: rgba(255,255,255,.8);
                transition: all .3s ease-out;
                font-weight: 400;
                max-width: 100%;
                height: auto;
            }
            .button-search {
                position: absolute;
                top: 44px;
                display: block;
                right: 5px;
                border: 0;
                background: 0 0;
                padding: 0;
                color: rgba(255,255,255,.8);
                font-size: 30px;
                height: 30px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.wrapper-2{
    .body-overlay-2 {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-2 .body-overlay-2 {
        opacity: 1;
        visibility: visible;
    }
} 

.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 420px;
	padding: 104px 50px 50px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 350px;
        padding: 104px 30px 50px;
    }
    @media #{$xs-layout} {
        padding: 50px 15px 50px;
        width: 280px;
    }
    .sidebar-cart-all {
        .cart-close {
            position: absolute;
            top: 55px;
            right: 55px;
            font-size: 30px;
            line-height: 30px;
            transition: all 250ms ease-out;
            @media #{$xs-layout} {
                top: 20px;
                right: 23px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color;
            }
        }
        .cart-content {
            > h3 {
                font-size: 18px;
                font-weight: 700;
                margin: 0 0 45px;
            }
            ul {
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: 310px;
                max-height: calc(100vh - 430px);
                max-height: -webkit-calc(100vh - 430px);
                overflow: auto;
                @media #{$md-layout} {
                    max-height: calc(100vh - 365px);
                }
                @media #{$xs-layout} {
                    max-height: calc(100vh - 360px);
                }
                li {
                    display: flex;
                    margin-bottom: 30px;
                    .cart-img {
                        flex: 0 0 70px;
                        a {
                            img {
                                width: 100%
                            }
                        }
                    }
                    .cart-title {
                        margin-left: 20px;
                        h4 {
                            font-size: 14px;
                            margin: 0 0 4px;
                            font-weight: 500;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                    .cart-delete {
                        display: flex;
                        flex-grow: 100;
                        justify-content: flex-end;
                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
            .cart-total {
                display: block;
                width: 100%;
                margin: 8px 0 37px;
                h4 {
                    font-size: 18px;
                    margin: 0;
                    font-weight: 500;
                    span {
                        float: right;
                    }
                }
            }
            .cart-checkout-btn {
                display: block;
                width: 100%;
                a{
                    width: 100%;
                    display: block;
                    margin: 10px 0 0;
                    text-align: center;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}










.stick {
    .main-menu {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 80px;
                        span {
                            top: 8px;
                        }
                    }
                }
            }
        }
    }
    .header-right-wrap {
        padding: 24px 0;
    }
}




.stick {
    .header-small-mobile {
        .header-right-wrap {
            padding: 3px 0 0;
        }
    }
}

.header-small-mobile {
    .header-right-wrap {
        padding: 3px 0 0;
        .cart-wrap {
            .shopping-cart-content {
                .shopping-cart-top {
                    padding-bottom: 10px;
                    margin-bottom: 15px;
                }
                top: 51px;
                height: calc(100vh - 79px);
                &.show {
                    right: -40px;
                }
                .shopping-cart-bottom {
                    margin-top: 12px;
                    a {
                        padding: 9px 40px 10px;
                        font-size: 13px;
                    }
                    .shopping-cart-total {
                        padding: 12px 0px 11px;
                        margin: 15px 0 15px;
                    }
                }
            }
        }
        .mobile-off-canvas {
            margin-left: 20px;
            @media #{$xs-layout} {
                margin-left: 12px;
            }
            a {
                font-size: 24px;
            }
        }
    }
}

.main-wrapper {
    .body-overlay {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active .body-overlay {
        opacity: 1;
        visibility: visible;
    }
} 

.mobile-off-canvas-active {
	position: fixed;
	top: 0;
	width: 330px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: -330px;
	visibility: hidden;
	opacity: 1;
	transition: all 400ms ease-out;
    background-color: #fff;
    box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$xs-layout} {
        width: 260px;
        right: -260px;
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        right: 0px;
    }
    .mobile-aside-close {
        position: absolute;
        left: -40px;
        top: 0px;
        z-index: 9;
        width: 40px;
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        background-color: #343538;
        i{
            transition: all .3s ease-in-out;
            display: inline-block;
            color: #fff;
        }
        &:hover i{
            color: $theme-color;
            transform: rotate(90deg);
        }
    }
    & .header-mobile-aside-wrap {
        padding: 40px 30px 50px;
        overflow: auto;
        height: 100%;
        @media #{$xs-layout} {
            padding: 30px 20px 50px;
        }
        .mobile-search {
            border-bottom: 1px solid #ddd;
            margin-bottom: 27px;
            padding-bottom: 40px;
            form {
                position: relative;
                input {
                    background-color: #f6f6f6;
                    border: none;
                    border-radius: 0px;
                    height: 50px;
                    padding: 0 60px 0 15px;
                    width: 100%;
                    font-size: 14px;
                    color: #000;
                }
                button {
                    background-color: transparent;
                    border-color: #ddd;
                    border-image: none;
                    border-radius: 5px 0 0 5px;
                    border-style: none none none solid;
                    border-width: medium medium medium 1px;
                    color: #000000;
                    font-size: 18px;
                    height: 100%;
                    padding: 0 15px 0 14px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s ease 0s;
                    @media #{$xs-layout} {
                        font-size: 16px;
                    }
                    &:hover{
                        color: $theme-color;
                    }
                    i {
                        margin-top: 5px;
                        display: inline-block;
                    }
                }
            }
        }
        .mobile-menu-wrap {
            border-bottom: 1px solid #ddd;
            margin-bottom: 36px;
            padding-bottom: 27px;
            .mobile-navigation {
                nav {
                    height: 100%;
                    .mobile-menu {
                        li {
                            display: block;
                            position: relative;
                            &.menu-item-has-children {
                                .menu-expand {
                                    line-height: 50;
                                    top: -5px;
                                    left: 95%;
                                    width: 30px;
                                    position: absolute;
                                    height: 50px;
                                    text-align: center;
                                    cursor: pointer;
                                    i {
                                        display: block;
                                        position: relative;
                                        width: 10px;
                                        margin-top: 25px;
                                        border-bottom: 1px solid #000;
                                        transition: all 250ms ease-out;
                                        &::before {
                                            top: 0;
                                            width: 100%;
                                            content: "";
                                            display: block;
                                            position: absolute;
                                            transform: rotate(90deg);
                                            border-bottom: 1px solid #000;
                                            transition: 0.4s;
                                        }
                                    }
                                }
                                &.active > .menu-expand i::before{
                                    transform: rotate(0);
                                }
                            }
                            a {
                                font-size: 13px;
                                text-transform: uppercase;
                                line-height: 18px;
                                position: relative;
                                display: inline-block;
                                padding: 10px 0;
                                font-weight: 500;
                                font-family: $montserrat;
                                color: #000;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                            ul {
                                li {
                                    a {
                                        padding: 10px 15px 5px;
                                        font-size: 14px;
                                        color: #333;
                                        font-weight: 400;
                                        text-transform: capitalize;
                                        font-family: $opensans;
                                        &:hover {
                                            color: $theme-color;
                                        }
                                    }
                                    ul {
                                        li {
                                            a {
                                                padding: 10px 30px 5px;
                                                font-size: 14px;
                                                color: #000000;
                                                font-weight: 300; 
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .mobile-curr-lang-wrap {
            border-bottom: 1px solid #ddd;
            margin-bottom: 31px;
            padding-bottom: 37px;
            @media #{$xs-layout} {
                display: block;
            }
            .single-mobile-curr-lang {
                position: relative;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: 13px;
                    display: block;
                    font-weight: 500;
                    font-family: $montserrat;
                    color: #000;
                    text-transform: uppercase;
                    i {
                        float: right;
                        font-size: 10px;
                        position: relative;
                        top: 8px;
                    }
                }
                .lang-curr-dropdown {
                    margin-top: 5px;
                    display: none;
                    background-color: #fff;
                    box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                    padding: 22px 30px 26px;
                    width: 100%;
                    z-index: 11;
                    ul {
                        li {
                            padding-bottom: 10px;
                            &:last-child {
                                padding-bottom: 0px;
                            }
                            a {
                                font-size: 14px;
                                font-weight: 400;
                                text-transform: capitalize;
                                font-family: $opensans;
                                color: #333;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                }
                &:hover > a {
                    color: $theme-color;
                }
                @media #{$xs-layout} {
                    margin-bottom: 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .quick-info {
            margin-bottom: 20px;
            ul {
                li {
                    margin-bottom: 10px;
                    font-size: 13px;
                    font-weight: 500;
                    font-family: $montserrat;
                    color: #000;
                    a {
                        color: #000;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    i {
                       font-size: 18px;
                        margin: 0 5px 0 0;
                    }
                }
            }
        }
    }
}

.transparent-bar {
	left: 0;
	right: 0;
	position: absolute;
	width: 100%;
	z-index: 999;
    top: 0;
}




/*-------- 9. Blog style ---------*/

.blog-wrap {
    .blog-img {
        overflow: hidden;
        a {
            img {
                width: 100%;
                transform: scale(1);
                transition: all .5s ease 0s;
            }
        }
    }
    .blog-content {
        .blog-category {
            a {
                font-style: italic;
                color: #666;
                font-size: 15px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        h3 {
            font-weight: 700;
            font-size: 20px;
            margin: 7px 0 8px;
            @media #{$lg-layout} {
                font-size: 18px;
            }
            @media #{$xs-layout} {
                font-size: 18px;
            }
        }
        .blog-meta {
            a {
                display: inline-block;
                margin: 0 10px;
                font-size: 15px;
                color: #666;
                @media #{$lg-layout} {
                    margin: 0 5px;
                }
                &:hover {
                    color: $theme-color;
                }
                i {
                    font-size: 18px;
                    padding-right: 5px;
                }
            }
        }
    }
    p {
        margin: 7px auto 6px;
        width: 90%;
        @media #{$xs-layout} {
            width: 100%;
        }
        @media #{$sm-layout} {
            width: 85%;
        }
    }
    .blog-btn {
        a {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: .8px;
            padding: 0 0 3px;
            border-bottom: 1px solid #666;
            display: inline-block;
            line-height: 1;
            &:hover {
                border-bottom: 1px solid $theme-color;
            }
        }
    }
    &:hover .blog-img a img {
        transform: scale(1.1);
    }
}






/*-------- 5. Deal style ---------*/

.deal-img {
    img {
        width: 100%;
    }
}

.deal-content {
    h3 {
        font-size: 36px;
        color: #e32222;
        font-family: $meddon;
        margin: 0;
        line-height: 55px;
    }
    h2 {
        text-transform: capitalize;
        font-weight: 800;
        font-size: 40px;
        margin: 4px 0 13px;
        line-height: 50px;
        @media #{$xs-layout} {
            font-size: 28px;
            margin: 4px 0 10px;
            line-height: 25px;
        }
    }
    > p {
        color: #333;
        font-style: italic;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
    }
    .timer {
        margin: 37px 0 40px;
        @media #{$xs-layout} {
            margin: 30px 0 20px;
        }
        span {
            display: inline-block;
            width: 90px;
            height: 90px;
            font-weight: 700;
            text-align: center;
            font-size: 32px;
            border: 1px solid #333;
            font-family: $montserrat;
            margin: 0 12px;
            padding: 17px 0 17px;
            @media #{$lg-layout} {
                width: 85px;
                height: 85px;
                margin: 0 7px;
            }
            @media #{$xs-layout} {
                margin: 0 4px 12px;
            }
            p {
                text-transform: capitalize;
                font-weight: 500;
                margin: 7px 0 0;
                font-family: $montserrat;
                color: #333;
                font-size: 15px;
            }
        }
    }
}


@media #{$lg-layout} {
    .deal-area.bg-img {
        background-position: 57%;
    }
}

@media #{$xs-layout} {
    .deal-area.bg-img {
        background-position: 68%;
        position: relative;
        &:before  {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;
            background-color: #fff;
            opacity: .5;
            content: "";
            height: 100%;
        }
    }
}
@media #{$md-layout} {
    .deal-area.bg-img {
        background-position: 68%;
        position: relative;
        &:before  {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;
            background-color: #fff;
            opacity: .5;
            content: "";
            height: 100%;
        }
    }
}










/*-------- 10. Instagram style ---------*/


.instagram-item {
    position: relative;
    overflow: hidden;
    a {
        display: block;
        img {
            transition: all .5s ease 0s;
            transform: scale(1.1);
        }
        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            z-index: 99;
            font-size: 50px;
            color: #fff;
            visibility: hidden;
            transition: all .5s ease 0s;
            &:hover {
                color: $theme-color;
            }
        }
        &::before {
            background-color: rgba(52, 53, 56, 0.4);
            z-index: 9;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            right: 0;
            bottom: 0;
            transition: all .3s ease-out;
            opacity: 0;
            visibility: hidden;
        }
    }
    &:hover a img {
        transform: scale(1);
    }
    &:hover a i {
        transform: translate(-50%, -50%) scale(1);
        visibility: visible;
    }
    &:hover a::before {
        opacity: 1;
        visibility: visible;
    }
}








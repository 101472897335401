/*-------- 13. Brand logo style ---------*/

.brand-logo-active {
    &.owl-carousel {
        .owl-item {
            img {
                display: inline-block;
                width: auto;
            }
        }
    }
    .single-brand-logo {
        text-align: center;
    }
}





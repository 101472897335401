/*-------- 11. Footer style ---------*/

.footer-widget {
    .footer-title {
        h3 {
            font-weight: 600;
            font-size: 18px;
            color: #333;
            margin: 0 0 33px;
            line-height: 1;
            @media #{$xs-layout} {
                margin: 0 0 25px;
            }
        }
    }
    .footer-logo {
        margin-bottom: 25px;
        p {
            font-size: 15px;
            margin: 20px 0 0;
        }
    }
    .footer-contact {
        .single-footer-contact {
            display: flex;
            margin-bottom: 15px;
            &:Last-child {
                margin-bottom: 0;
            }
            .footer-contact-icon {
                margin-right: 20px;
                @media #{$xs-layout} {
                    margin-right: 10px;
                }
                @media #{$sm-layout} {
                    margin-right: 12px;
                }
                i {
                    font-size: 20px;
                    color: #333;
                    display: inline-block;
                    text-align: center;
                    transition: all .3s ease 0s;
                }
            }
            .footer-contact-content {
                p {
                    font-size: 15px;
                }
            }
        }
    }
    .footer-list {
        ul {
            li {
                margin-bottom: 21px;
                @media #{$xs-layout} {
                    margin-bottom: 12px;
                }
                a {
                    font-size: 15px;
                    display: block;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .subscribe-style {
        p {
            font-size: 15px;
        }
        .subscribe-form {
            .mc-form {
                position: relative;
                > input {
                    background: #fff;
                    line-height: 32px;
                    padding: 10px 130px 10px 20px;
                    margin: 0;
                    border: 2px solid rgba(51, 51, 51, 0.1);
                    font-size: 14px;
                    height: auto;
                    @media #{$xx-layout} {
                        padding: 10px 120px 10px 10px;
                    }
                    @media #{$xl-layout} {
                        padding: 10px 112px 10px 10px;
                    }
                    @media #{$lg-layout} {
                        padding: 10px 92px 10px 10px;
                    }
                }
                .mc-news {
                    display: none;
                }
                .clear {
                    position: absolute;
                    right: 0;
                    top: 1px;
                    input {
                        color: #fff;
                        background-color: #333;
                        width: 120px;
                        text-transform: uppercase;
                        font-size: 12px;
                        border: none;
                        font-weight: 700;
                        height: auto;
                        padding: 15px 0;
                        @media #{$xx-layout} {
                            width: 108px;
                        }
                        @media #{$xl-layout} {
                            width: 108px;
                        }
                        @media #{$lg-layout} {
                            width: 90px;
                        }
                        &:hover {
                            background-color: $theme-color;
                        }
                    }
                }
            }
            &.subscribe-form-2 {
                .mc-form {
                    > input {
                        padding: 10px 115px 10px 15px;
                        line-height: 25px;
                        @media #{$lg-layout} {
                            padding: 10px 92px 10px 10px;
                        }
                    }
                }
                .clear {
                    top: 2px;
                    input {
                        width: 100px;
                        padding: 11px 0;
                        @media #{$lg-layout} {
                            width: 88px;
                        }
                    }
                }
            }
        }
    }
    @media #{$lg-layout} {
        &.pl-50 {
            padding-left: 0;
        }
    }
    @media #{$md-layout} {
        &.pl-50 {
            padding-left: 0;
        }
    }
    @media #{$xs-layout} {
        &.pl-50 {
            padding-left: 0;
        }
        &.pl-20 {
            padding-left: 0;
        }
    }
}

.footer-top {
    @media #{$xs-layout} {
        &.pb-60 {
            padding-bottom: 10px;
        }
    }
}





/*-------- 22. Blog details style ---------*/

.blog-details-top {
    .blog-details-img {
        img {
            width: 100%;
        }
        @media #{$xs-layout} {
            &.mt-50 {
                margin-top: 30px;
            }
            &.mb-45 {
                margin-bottom: 25px;
            }
        }
    }
    .blog-details-content {
        > span {
            font-style: italic;
            color: #666;
            font-size: 15px;
        }
        h3 {
            font-weight: 700;
            font-size: 22px;
            margin: 7px 0 8px;
            @media #{$lg-layout} {
                font-size: 20px;
            }
            @media #{$xs-layout} {
                font-size: 18px;
                line-height: 27px;
            }
        }
        .blog-dec-meta {
            margin: 0 0 20px;
            span {
                display: inline-block;
                margin: 0 10px 0 0;
                font-size: 15px;
                color: #666;
                i {
                    font-size: 18px;
                    padding-right: 5px;
                }
            }
        }
        p {
            line-height: 28px;
            font-size: 15px;
            margin: 0 0 20px;
        }
        blockquote {
            padding: 40px 40px 63px;
            border: 1px solid #e8e8e8;
            text-align: center;
            position: relative;
            margin: 50px 0 50px;
            @media #{$lg-layout} {
                padding: 40px 25px 63px;
            }
            @media #{$xs-layout} {
                padding: 20px 20px 63px;
                margin: 30px 0 50px;
            }
            p {
                font-size: 20px;
                color: #333;
                line-height: 35px;
                margin: 0;
                @media #{$xs-layout} {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            span {
                position: absolute;
                bottom: -26px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                border: 1px solid #e8e8e8;
                font-size: 14px;
                padding: 14px 20px;
                text-transform: uppercase;
                font-style: italic;
                color: #666;
                @media #{$xs-layout} {
                    padding: 8px 20px;
                }
            }
        }
    }
}

.blog-comment-wrapper {
    @media #{$xs-layout} {
        &.mt-55 {
            margin-top: 25px;
        }
    }
    .blog-dec-title {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        text-transform: capitalize;
        @media #{$xs-layout} {
            font-size: 18px;
        }
    }
    .single-comment-wrapper {
        display: flex;
        @media #{$xs-layout} {
            display: block;
            &.mt-35 {
                margin-top: 20px;
            }
            &.mt-50 {
                margin-top: 30px;
            }
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .blog-comment-img {
            flex: 0 0 120px;
            margin-right: 28px;
            @media #{$xs-layout} {
                margin-right: 28px;
                margin-bottom: 20px;
            }
            img {
                width: 100%;
                @media #{$xs-layout} {
                    width: auto;
                }
            }
        }
        .blog-comment-content {
            h4 {
                font-size: 15px;
                font-weight: 600;
                margin: 0;
                line-height: 1;
                text-transform: capitalize;
            }
            span {
                display: block;
                font-size: 14px;
                margin: 2px 0 8px;
            }
            p {
                font-size: 14px;
                margin-bottom: 10px;
            }
            .blog-details-btn {
                a {
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
        &.ml-120 {
            @media #{$xs-layout} {
                margin-left: 0;
            }
        }
    }
}

.next-previous-post {
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    padding: 18px 0 17px;
    display: flex;
    justify-content: space-between;
    a{
        font-size: 15px;
        text-transform: capitalize;
        font-weight: 600;
        i {
            font-size: 16px;
            position: relative;
            top: 1px;
        }
    }
    @media #{$xs-layout} {
        &.mt-50 {
            margin-top: 22px;
        }
    }
}

.blog-reply-wrapper {
    @media #{$xs-layout} {
        &.mt-50 {
            margin-top: 30px;
        }
    }
    .blog-dec-title {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        text-transform: capitalize;
        @media #{$xs-layout} {
            font-size: 18px;
        }
    }
    .blog-form {
        margin-top: 35px;
        @media #{$xs-layout} {
            margin-top: 20px;
        }
        .leave-form {
            input {
                background: transparent none repeat scroll 0 0;
                border: 1px solid #eaeaea;
                box-shadow: none;
                height: 50px;
                margin-bottom: 30px;
                padding: 2px 20px 2px 20px;
                color: #333;
            }
        }
        .text-leave {
            textarea {
                background: transparent none repeat scroll 0 0;
                border: 1px solid #eaeaea;
                box-shadow: none;
                height: 250px;
                margin-bottom: 20px;
                padding: 20px 20px 2px 20px;
                color: #333;
                @media #{$xs-layout} {
                    height: 180px;
                }
            }
            input {
                background-color: $theme-color;
                border: medium none;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                font-weight: 600;
                height: inherit;
                margin-top: 10px;
                padding: 14px 30px 13px;
                width: inherit;
                font-size: 14px;
                &:hover {
                    background-color: #333;
                }
            }
        }
    }
}


@media #{$md-layout} {
    .blog-details-wrapper {
        &.ml-20 {
            margin-left: 0;
        }
    }
} 
@media #{$xs-layout} {
    .blog-details-wrapper {
        &.ml-20 {
            margin-left: 0;
        }
    }
} 





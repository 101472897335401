/*-------- 14. Video style ---------*/

.video-bg-img {
    position: relative;
    &::before {
        background: #000 none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        opacity: .3;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .video-content {
        position: relative;
        z-index: 9;
        h2 {
            font-size: 50px;
            font-weight: bold;
            color: #fff;
            text-transform: capitalize;
            font-family: $meddon;
            margin: 0 0 10px;
            @media #{$xs-layout} {
                font-size: 30px;
            }
        }
        .video-icon {
            a {
                font-size: 50px;
                color: #fff;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}
/*-------- 27. 404 style ---------*/



.error-content {
    img {
        width: 100%;
    }
    h2 {
        font-weight: 600;
        margin: 50px 0 40px;
        font-size: 25px;
        @media #{$lg-layout}{
            font-size: 22px;
        }
        @media #{$md-layout}{
            font-size: 22px;
        }
        @media #{$xs-layout}{
            font-size: 20px;
            margin: 30px 0 25px;
        }
    }
    .error-btn {
        margin: 0 0 30px;
    }
}

























































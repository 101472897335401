/*-------- 15. Breadcrumb style ---------*/

.breadcrumb-content {
    .breadcrumb-title {
        h2 {
            font-size: 28px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 0 5px;
            @media #{$xs-layout}{
                font-size: 23px;
                margin: 0 0 2px;
            }
        }
    }
    ul{
        li{
            display: inline-block;
            font-size: 15px;
            font-weight: 400;
            margin-right: 22px;
            text-transform: capitalize;
            position: relative;
            &:last-child {
                margin-right: 0;
            }
            &::before {
                position: absolute;
                width: 15px;
                height: 1px;
                background-color: #1f2226;
                content: "";
                right: -21px;
                top: 13px;
                z-index: 99;
                transform: rotate(115deg);
            }
            &:last-child::before {
                display: none;
            }
            a {
                font-weight: 600;
                &:hover {
                    color: $theme-color; 
                }
            }
        }
    }
}








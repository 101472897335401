/*-------- 3. Slider style ---------*/



.slider-height-1 {
    height: 840px;
    display: flex;
    align-items: center;
    @media #{$lg-layout} {
        height: 720px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: auto;
        padding: 110px 0 70px;
    }
}

.slider-content {
    h3 {
        font-size: 36px;
        color: $theme-color;
        font-family: $meddon;
        margin: 0;
        @media #{$sm-layout} {
            font-size: 32px;
        }
    }
    h1 {
        font-size: 48px;
        font-weight: bold;
        caption-side: #000000;
        line-height: 69px;
        letter-spacing: .8px;
        text-transform: uppercase;
        margin: 16px 0 43px;
        @media #{$lg-layout} {
            font-size: 40px;
            margin: 16px 0 35px;
            line-height: 55px;
        }
        @media #{$md-layout} {
            font-size: 35px;
            line-height: 45px;
            margin: 16px 0 30px;
        }
        @media #{$xs-layout} {
            font-size: 28px;
            line-height: 40px;
            margin: 12px 0 25px;
        }
        @media #{$sm-layout} {
            font-size: 24px;
            line-height: 34px;
            margin: 5px 0 15px;
        }
    }
}
.slider-content-2 {
    @media #{$xs-layout} {
        margin-top: 30px;
    }
    @media #{$sm-layout} {
        margin-top: 0px;
    }
    h3 {
        font-size: 50px;
        color: $theme-color;
        letter-spacing: .8px;
        margin: 0;
        font-family: $meddon;
        @media #{$xs-layout} {
            font-size: 45px;
        }
    }
    h1 {
        font-weight: bold;
        color: #000000;
        font-size: 80px;
        text-transform: uppercase;
        letter-spacing: .8px;
        margin: 14px 0 18px;
        @media #{$lg-layout} {
            font-size: 70px;
        }
        @media #{$md-layout} {
            font-size: 50px;
        }
        @media #{$xs-layout} {
            font-size: 40px;
            margin: 14px 0 12px;
        }
        @media #{$sm-layout} {
            font-size: 40px;
        }
    }
    h4 {
        font-size: 16px;
        color: #000000;
        letter-spacing: 9px;
        text-transform: uppercase;
        margin: 0 0 56px;
        @media #{$md-layout} {
            font-size: 14px;
            letter-spacing: 7px;
            margin: 0 0 40px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            letter-spacing: 4px;
            margin: 0 0 40px;
        }
    }
}

.slider-single-img-2 {
    margin: 0 80px;
    @media #{$md-layout} {
        margin: 0 20px;
    }
    @media #{$xs-layout} {
        margin: 0 0px;
    }
}

@media #{$xs-layout} {
    .slider-content {
        padding: 50px 0 30px;
        text-align: center;
    }
}
@media #{$sm-layout} {
    .slider-content {
        padding: 0px 0 0px;
    }
}


.owl-item .slider-content * {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-item.active {
    .slider-animated-1 {
        h3 {
            -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
            -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
        }
        h1 {
            -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
            -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
        }
        a {
            -webkit-animation-delay: 1.7s;
            animation-delay: 1.7s;
            -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
        }
        &.slider-single-img img {
            -webkit-animation-delay: 1.6s;
            animation-delay: 1.6s;
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }
    }
    .slider-animated-2 {
        h3 {
            -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }
        h1 {
            -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }
        h4 {
            -webkit-animation-delay: 1.8s;
            animation-delay: 1.8s;
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }
        a {
            -webkit-animation-delay: 1.9s;
            animation-delay: 1.9s;
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }
        &.slider-single-img-2 img {
            -webkit-animation-delay: 1.6s;
            animation-delay: 1.6s;
            -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
        }
    }
}

.nav-style-1{
    &.owl-carousel {
        .owl-nav div {
            color: #1f2226;
            display: inline-block;
            font-size: 22px;
            left: 18px;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease 0s;
            border: 1px solid #e1e2e2;
            background-color: $white;
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 100%;
            @media #{$lg-layout} {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 15px;
            }
            @media #{$md-layout} {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
            @media #{$xs-layout} {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
            &:hover {
                background-color: $theme-color;
                color: $white;
                border: 1px solid $theme-color;
            }
            &.owl-next {
                left: auto;
                right: 18px;
            }
        }
    }
}
.slider-area:hover .nav-style-1.owl-carousel > .owl-nav div {
	opacity: 1;
	visibility: visible;
}










/*-------- 6. Section title style ---------*/


.section-title {
    h2 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 30px;
        @media #{$xs-layout} {
            margin-bottom: 10px;
            font-size: 26px;
        }
    }
    p {
        margin: 0 auto;
        line-height: 26px;
    }
    @media #{$xs-layout} {
        &.mb-45 {
            margin-bottom: 30px;
        }
    }
}



